import type { FC, ReactNode } from "react";
import React, { Fragment, useState, useEffect } from "react";
import { PasswordFieldInverted } from "pages/auth/password";
import { ReactComponent as IconLogo } from "../assets/images/icon_logo.svg";
import { useWindowSize } from "hooks/useWindowSize";
import Mobile from "components/Others/desktop-only-warning";
import { GlassWidget } from "components/Modal/glassMorphModal";
import { Transition } from "@headlessui/react";
import { AuthLink } from "components/Link/index"

export interface AuthLayoutProps {
  children: ReactNode;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  const { width, height } = useWindowSize();

  return width > 766 ? (
    <div className="relative flex min-h-screen flex-col items-center justify-center text-white gradient">
      <SubtleTopLogo />
      {children}
    </div>
  ) : (
    <Mobile />
  );
};

export const AuthGlassWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <GlassWidget>
      <div className="flex flex-row h-full justify-center font-mono my-6">{children}</div>
    </GlassWidget>
  );
};

export const AuthContentWrapper = ({ children }: { children: ReactNode }) => {
  return <div className="px-12 flex flex-col gap-6">{children}</div>;
};

export const AuthFormHeader = ({
  title,
  subtitle,
  children,
}: {
  title: string;
  subtitle?: string;
  children?: ReactNode;
}) => {
  return (
    <div>
      <b className="text-2xl" data-cy="welcome-text">
        {title}
      </b>
      <p className="text-xs">
        {subtitle ? subtitle : null}
        {children}
      </p>
    </div>
  );
};

export const AuthForm = ({
  submit,
  children,
}: {
  submit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  children: ReactNode;
}) => {
  return (
    <form onSubmit={submit} className="flex flex-col gap-4 text-xs">
      {children}
    </form>
  );
};

export const AuthFormTextItem = ({
  label,
  value,
  changeHandler,
  type,
  placeholder,
}: {
  label: string;
  value: string;
  changeHandler: (value: React.SetStateAction<string>) => void;
  type: string;
  placeholder: string;
}) => {
  return (
    <div>
      <label className="text-sm text-white/70">{label}</label>
      <input
        value={value}
        onChange={(e) => changeHandler(e.target.value)}
        type={type}
        placeholder={placeholder}
        className="input rounded-lg w-full hover:border-white/40 bg-white/60 text-base-text focus:text-white clicked:bg-blue focus:bg-blue transition-all"
      />
    </div>
  );
};

export const AuthFormPasswordItem = ({
  label,
  value,
  changeHandler,
  hideStrengthBar,
  // checkPassword,
  score,
}: {
  label: string;
  value: string;
  changeHandler: (value: string) => void;
  hideStrengthBar?: boolean;
  score: number;
}) => {
  return (
    <div>
      <label className="text-sm text-white/70">{label}</label>
      <PasswordFieldInverted
        password={value}
        checkPassword={changeHandler}
        score={score}
        hideStrengthBar={hideStrengthBar}
      />
    </div>
  );
};

export const AuthGlassWrapperFooter = ({
  text,
  children,
}: {
  text: string;
  children: ReactNode;
}) => {
  return (
    <div className="py-3 text-xs">
      <p>
        {text}
        <span>{children}</span>
      </p>
    </div>
  );
};

const AuthTopLogoBar = () => {
  const invertedBrandGradient = {
    background: "linear-gradient(124.83deg, #F4BB78 -33.66%, #DD31E1 212.44%)",
  };

  return (
    <div className="flex flex-row items-center justify-center bg-purple-background border border-solid border-border-internal">
      <div className="flex flex-row items-center my-2 py-2 ">
        <div
          style={invertedBrandGradient}
          className="p-2 justify-center flex items-center rounded-lg"
        >
          <IconLogo />
        </div>
        <span className="text-xl tracking-tighter px-2 font-bold text-base-text">Bucephalus</span>
      </div>
    </div>
  );
};

const SubtleTopLogo = () => {
  const invertedBrandGradient = {
    background: "linear-gradient(124.83deg, #F4BB78 -33.66%, #DD31E1 212.44%)",
  };

  return (
    <div className="relative top-0 flex flex-row items-center mt-6">
      <div className="p-2 justify-center flex items-center opacity-60">
        <IconLogo />
      </div>
      {/* <span className="text-xl px-2 font-medium text-white/50">Bucephalus</span> */}
    </div>
  );
};

export const GlassContentList = ({ data }: { data: any[] }) => {
  const [showData, setShowData] = useState<any[]>([]);
  const [index, setIndex] = useState(0);

  const transitionClasses = {
    enter: "transform transition duration-[650ms]",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "transform transition duration-[650ms]",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0",
  };

  function addElement(showData: any[]) {
    setTimeout(() => {
      setIndex(index + 1);
    }, 2000);
  }

  // return (
  //   <>
  //     {showData.slice(0, index).map((item: string, i: number) => {
  //       console.log({item})
  //       return (
  //         <Transition
  //           key={item + i}
  //           appear={true}
  //           show={true}
  //           {...transitionClasses}
  //         >
  //           <GlassColumn span="col-span-12 h-fit">
  //             {item}
  //           </GlassColumn>
  //         </Transition>
  //       );
  //     })}
  //   </>
  // );
  return (
    <>
      {data.map((item: string, i: number) => {
        return (
          <Transition key={item + i} appear={true} show={true} {...transitionClasses}>
            <GlassColumn span="col-span-12 h-fit">{item}</GlassColumn>
          </Transition>
        );
      })}
    </>
  );
};

export const GlassColumn = ({ span, children }: { span?: string; children: ReactNode }) => {
  const style = span
    ? `${span} flex flex-col w-full gap-8 bg-white/10 rounded-xl border border-white/10`
    : "flex flex-col w-fit gap-8 bg-white/10 rounded-xl border border-white/10";
  return (
    <div className={style}>
      <div className="font-mono my-6">{children}</div>
    </div>
  );
};

export default AuthLayout;
