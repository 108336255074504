import { useState } from "react";
import { UnitLoadingWrapper } from "components/Loading";
import { AsyncStatus } from "types";
import { LinkLookalike } from "components/Link/index";

interface ReadMoreProps {
  text: string;
  maxLength: number;
  loading: AsyncStatus;
}

const ReadMore = ({ text, maxLength, loading }: ReadMoreProps) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  const displayText = isTruncated ? `${text.slice(0, maxLength)}...` : text;

  return (
    <UnitLoadingWrapper loading={loading}>
      <div>{displayText}<span>{text.length > maxLength && (
        <LinkLookalike onClick={toggleTruncate}>
          {isTruncated ? "Read more" : "Show less"}
        </LinkLookalike>  
      )}</span></div>
      
    </UnitLoadingWrapper>
  );
};

export default ReadMore;
