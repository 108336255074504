import { ROW_SPAN_KEY } from "./row-span-formatter";
import React, { useEffect, useState, lazy, Suspense, useContext } from "react";
import { Cell } from "@tanstack/react-table";
import { TableIconButton } from "components/Button";
import {
  formatNumber,
  styleList,
  baseSpacing,
  baseWrapper,
  baseInner,
  hoverable,
  clickable,
  tdBorderStyle,
  rowGroupingBorder,
  lastRowStyle,
  validateNumber,
} from "utils/helper-ts";
import { capitalCase } from "change-case";
import { STRICT_CELL_HEIGHT } from "pages/demand/table";
import { IObject } from "types";
import { ReactComponent as Edit } from "assets/images/edit.svg";
import { ReactComponent as EditModal } from "assets/images/up-to-right.svg";
import { RegressionSpark } from "./regression-spark-cell";
import { DemandSpark } from "./demand-spark-cell";
import { LegendItem } from "components/ChartLegend";
import { STACKED_AREA_TAILWIND } from "utils/raw-colors";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { DemandContext, ModalContext } from "context";
import { InventorySpark } from "./inventory-spark-cell";
import { AddCostButton, DataQualityButton } from "components/Button";

const Tooltip = lazy(() =>
  import("@material-tailwind/react").then((module) => ({ default: module.Tooltip }))
);

interface DefaultCellProps {
  value: string;
}

interface PillCellProps extends DefaultCellProps {
  pill: React.ReactNode;
}

// this is the <td> usage to comply with tanstack 8
export const CellWrapper = ({
  cell,
  children,
  width,
  styles,
  handleClick,
}: {
  cell: Cell<any, any>;
  children: React.ReactNode;
  width?: number;
  styles?: string;
  handleClick?: any;
}) => {
  return (
    <td
      key={cell.id}
      style={{
        width: width || cell.column.getSize(),
        height: STRICT_CELL_HEIGHT.toString() + "em",
        cursor: handleClick ? "pointer" : "auto",
      }}
      className={styles}
      onClick={handleClick}
    >
      {children}
    </td>
  );
};

export const RowSpanWrapper = ({
  cell,
  children,
  span = 1,
  styles,
}: {
  cell: Cell<any, any>;
  children: React.ReactNode;
  span?: number;
  styles?: string;
}) => {
  if (cell.getValue() === ROW_SPAN_KEY) {
    return (
      <td
        key={cell.id}
        style={{ width: cell.column.getSize(), height: STRICT_CELL_HEIGHT.toString() + "em" }}
      >
        {null}
      </td>
    );
  } else {
    return (
      <td
        key={cell.id}
        style={{
          width: 300 || cell.column.getSize(), // another hack unfortunately
          height: (STRICT_CELL_HEIGHT * span).toString() + "em",
        }}
        className={styles}
      >
        {children}
      </td>
    );
  }
};

export const DefaultCell = ({
  cell,
  wrapperStyle,
  handleClick,
}: {
  cell: any;
  wrapperStyle?: string;
  handleClick?: any;
}) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle]);
  let innerStyles = styleList([baseInner, "w-full flex  font-mono"]);
  return (
    <CellWrapper cell={cell} styles={outerStyles} handleClick={handleClick}>
      <div className={styleList([baseWrapper])}>
        <p className={innerStyles}>{cell.getValue()?.toString()}</p>
      </div>
    </CellWrapper>
  );
};

// really general right now, should take other things into account
const badValueStyle = "flex font-mono text-button-badge-text text-orange-700";
const checkValueStyle = "flex text-gray-600 rounded-md font-mono";


// maybe we'll call this negative flag or something
export const UnitsOnHandCell = ({
  cell,
  wrapperStyle,
  handleClick,
  children,
}: {
  cell: any;
  wrapperStyle?: string;
  handleClick?: any;
  children?: React.ReactNode;
}) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle]);
  let innerStyles = styleList([baseInner, "w-full flex  font-mono"]);

  if (cell.getValue() < 0) {
    innerStyles = styleList([
      baseInner,
      badValueStyle,
    ]);
  }
  return (
    <CellWrapper cell={cell} styles={outerStyles} handleClick={handleClick}>
      {children}
      <div className={styleList([baseWrapper, "flex items-center"])}>
        <p className={innerStyles}>{cell.getValue()?.toString()}</p>
        {cell.getValue() < 0 && (
          <div className="mx-auto mr-2">
            <DataQualityButton goToShopify={true} productId={cell.row.original.productId} />
          </div>
        )}
      </div>
    </CellWrapper>
  );
};

// maybe we'll call this string flag
// this generalizing practice isn't working because I want the outcome of this to be adding costs
export const FlagStringCell = ({
  cell,
  wrapperStyle,
  handleClick,
}: {
  cell: any;
  wrapperStyle?: string;
  handleClick?: any;
}) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle]);
  let innerStyles = styleList([baseInner, "w-full flex  font-mono"]);

  if (typeof cell.getValue() !== "number") {
    innerStyles = styleList([
      baseInner,
      badValueStyle,
    ]);
  }

  let { variantId, productId } = cell.row.original;

  return (
    <CellWrapper cell={cell} styles={outerStyles} handleClick={handleClick}>
      {typeof cell.getValue() !== "number" && variantId && productId ? (
        <AddCostButton page="products" product={productId} variant={variantId} />
      ) : (
        <div className={styleList([baseWrapper])}>
          <p className={innerStyles}>{cell.getValue()?.toString()}</p>
        </div>
      )}
    </CellWrapper>
  );
};

export const FlagValueRange = ({
  cell,
  wrapperStyle,
  minValue,
  maxValue,
}: {
  cell: any;
  wrapperStyle?: string;
  minValue: number;
  maxValue: number;
}) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle]);
  let innerStyles = styleList([baseInner, "w-full flex  font-mono"]);

  if (cell.getValue() >= minValue && cell.getValue() <= maxValue) {
    innerStyles = styleList([baseInner, badValueStyle]);
  }

  if (cell.getValue() === "limited data") {
    innerStyles = styleList([baseInner, checkValueStyle]);
  }

  return (
    <CellWrapper cell={cell} styles={outerStyles}>
      <div className={styleList([baseWrapper])}>
        <p className={innerStyles}>{cell.getValue()?.toString()}</p>
      </div>
    </CellWrapper>
  );
};

export const CostCell = ({
  cell,
  wrapperStyle,
  currency,
  handleClick,
  variantId,
  productId,
}: {
  cell: any;
  wrapperStyle?: string;
  currency: string;
  handleClick?: any;
  variantId?: string;
  productId?: string;
}) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle]);
  let innerStyles = styleList([baseInner, "w-full flex  font-mono"]);

  if (cell.getValue() <= 0) {
    innerStyles = styleList([
      baseInner,
      "w-full flex  font-mono text-red-500 bg-gray-100 rounded-md",
    ]);
  }

  return (
    <CellWrapper cell={cell} styles={outerStyles} handleClick={handleClick}>
      <div className={styleList([baseWrapper])}>
        {cell.getValue() <= 0 ? (
          <AddCostButton
            page="products"
            product={productId || cell.row.original.productId}
            variant={variantId || cell.row.original.variantId}
          />
        ) : (
          <CurrencyCellInner currency={currency || ""} styles={innerStyles} cell={cell} />
        )}
      </div>
    </CellWrapper>
  );
};

export const DateCell = ({
  cell,
  wrapperStyle,
  handleClick,
}: {
  cell: any;
  wrapperStyle?: string;
  handleClick?: any;
}) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle]);
  let innerStyles = styleList([baseInner, "w-full flex  font-mono"]);
  let date = new Date(cell.getValue()).toLocaleDateString(undefined, {
    // weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  if (date === "Invalid Date") {
    date = "-";
  }
  return (
    <CellWrapper cell={cell} styles={outerStyles} handleClick={handleClick}>
      <div className={styleList([baseWrapper])}>
        <p className={innerStyles}>{date}</p>
      </div>
    </CellWrapper>
  );
};

// some cases require the inner div to be separate
export const DefaultInnerCell = ({ cell }: { cell: any }) => {
  let innerStyles = styleList([baseInner, "w-full flex justify-end font-mono"]);
  return (
    <div className={styleList([baseWrapper])}>
      <p className={innerStyles}>{cell.getValue()}</p>
    </div>
  );
};

export const MetricCell = ({ cell }: { cell: any }) => {
  let metric = cell.getValue();
  let outerStyles = styleList([tdBorderStyle, lastRowStyle(cell)]);
  let innerStyles = styleList([baseInner, "w-full flex justify-end text-2xs font-mono"]);
  // going to add a border here, even though it breaks the pattern :(

  function reNaming(metric: string) {
    switch (metric) {
      case "revenue":
        return "Revenue from Period";
      case "percentOfTotalRevenue":
        return "% of Revenue";
      case "yearToYear":
        return capitalCase("yearOverYear");
      default:
        return capitalCase(metric);
    }
  }

  return (
    <CellWrapper cell={cell} styles={outerStyles}>
      <div className={styleList([baseWrapper])}>
        <p className={innerStyles}>{reNaming(metric)}</p>
      </div>
    </CellWrapper>
  );
};

export const CurrencyCell = ({
  cell,
  currency,
  wrapperStyle,
}: {
  cell: any;
  currency: string;
  wrapperStyle?: string;
}) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle, lastRowStyle(cell)]);
  let innerStyles = styleList([baseInner, "w-full flex justify-end font-mono"]);

  return (
    <CellWrapper cell={cell} styles={outerStyles}>
      <div className={styleList([baseWrapper])}>
        <HoverForRowInfo cell={cell}>
          <CurrencyCellInner styles={innerStyles} currency={currency} cell={cell} />
        </HoverForRowInfo>
      </div>
    </CellWrapper>
  );
};

const CurrencyCellInner = ({
  cell,
  currency,
  styles,
}: {
  cell: any;
  currency: string;
  styles: string;
}) => {
  return <p className={styles}>{formatNumber(cell.getValue(), "currency", currency)}</p>;
};

export const QuantityCell = ({
  cell,
  wrapperStyle,
  noTooltip,
  percentage,
}: {
  cell: any;
  wrapperStyle?: string;
  noTooltip?: boolean;
  percentage?: number;
}) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle, lastRowStyle(cell)]);
  let innerStyles = styleList([baseInner, "w-full flex justify-end font-mono"]);

  return (
    <CellWrapper cell={cell} styles={outerStyles}>
      <div className={styleList([baseWrapper])}>
        {noTooltip ? (
          <p className={innerStyles}>{cell.getValue()}</p>
        ) : (
          <HoverForRowInfo cell={cell}>
            <p className={innerStyles}>{cell.getValue()}</p>
          </HoverForRowInfo>
        )}
        {percentage && percentage !== 0 && <PercentPill percent={percentage} />}
      </div>
    </CellWrapper>
  );
};

const HoverForRowInfo = ({ cell, children }: { cell: any; children: React.ReactNode }) => {
  if (cell.row.original.metric) {
    return (
      <Tooltip
        content={<RowInfo cell={cell} />}
        placement="left"
        className="z-50 relative max-w-sm border border-solid border-border-hover shadow-popup rounded-xl bg-purple-base font-sfmono text-sm leading-5 text-white overflow-hidden"
      >
        <div className="hover:bg-black/5 w-full rounded-md">{children}</div>
      </Tooltip>
    );
  } else {
    return <>{children}</>;
  }
};

const RowInfo = ({ cell }: { cell: any }) => {
  if (cell.row.original.metric) {
    return (
      <>
        {cell.row.original.organizeBy === "variant" ? (
          <p>
            <span className="font-mono-bold">{"Product"}</span>: {cell.row.original.productTitle}
          </p>
        ) : null}
        <p>
          <span className="font-mono-bold">{capitalCase(cell.row.original.organizeBy)}</span>:{" "}
          {cell.row.original.preservedGrouping}
        </p>
        <p>
          <span className="font-mono-bold">{"Metric"}</span>:{" "}
          {capitalCase(cell.row.original.metric)}
        </p>
      </>
    );
  } else {
    return null;
  }
};

export const PercentCell = ({ cell, wrapperStyle }: { cell: any; wrapperStyle?: string }) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle, lastRowStyle(cell)]);
  let innerStyles = styleList([baseInner, "w-full flex justify-end font-mono"]);
  return (
    <CellWrapper cell={cell} styles={outerStyles}>
      <div className={styleList([baseWrapper])}>
        <HoverForRowInfo cell={cell}>
          <p className={innerStyles}>{formatNumber(cell.getValue(), "percent")}</p>
        </HoverForRowInfo>
      </div>
    </CellWrapper>
  );
};

export const ImageCell = ({ cell }: { cell: any }) => {
  let styles = styleList([baseWrapper, hoverable]);
  let altStyle = styleList([baseWrapper, "text-gray-500 flex justify-center"]);
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  let value = cell.getValue();

  if (!value || imageError) {
    return (
      <div className={styleList(["flex justify-center items-center px-2"])}>
        <DataQualityButton
          goToShopify={true}
          productId={cell.row.original.productId || cell.row.original.pid}
          text={imageError ? "fix" : "add"}
        />
      </div>
    );
  }

  return (
    <Tooltip
      content={<img src={value} alt={value} width={300} height={300} />}
      placement="right"
      className="bg-base-lightwhite shadow-xl shadow-black/10 rounded-xl p-0 z-50 overflow-hidden border border-solid border-border-external"
    >
      <div className={styles + " flex justify-center hover:gray-100"}>
        <img
          src={value}
          alt={value}
          className="pointer-events-auto user-select-none h-[35px] rounded-md border border-solid border-border-external"
          title={""}
          onError={handleImageError}
        />
      </div>
    </Tooltip>
  );
};

export const EditableCell = (props: any) => {
  const {
    value: initialValue,
    row: { index, depth, id: rowId, canExpand, original },
    updateMyData,
    editableRows = [],
    revert,
    showBorder,
    percentage,
  } = props;
  const { updateRevertCells } = useContext(DemandContext);
  const [value, setValue] = useState(initialValue);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    updateMyData?.(e.target.value, original);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (revert) {
      setValue(initialValue);
      updateRevertCells(false);
    }
  }, [revert]);

  const isEditable = () => {
    return editableRows.some((row: IObject) => row.value === original[row.key]);
  };

  let styles = styleList([
    baseWrapper,
    // clickable,
    showBorder ? tdBorderStyle : "",
    "flex group",
    typeof validateNumber(value) === "boolean" ? "" : "bg-orange-50 text-orange-700",
  ]);

  return canExpand && depth === 0 ? (
    value
  ) : isEditable() || editableRows.length === 0 ? (
    <div className={styles}>
      <>
        <input
          className="w-full font-mono h-full outline-none bg-transparent transition-all border border-black border-opacity-0 focus:border-opacity-20 cursor-pointer rounded-md px-1 my-1 mx-1 group focus:bg-white"
          value={value}
          onChange={onChange}
        ></input>
        <Edit
          className="flex-end opacity-50 transition-all color-base-text group-hover:opacity-90 group-focus:opacity-90"
          title="edit"
        />
      </>
      {percentage && percentage !== 0 && <PercentPill percent={percentage} />}
    </div>
  ) : (
    value
  );
};

export const RegressionCell = ({
  cell,
  wrapperStyle,
  currency,
  width,
}: {
  cell: any;
  wrapperStyle: string;
  currency: string;
  width?: number;
}) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle]);
  let innerStyles = styleList([baseInner, "w-full flex  font-mono"]);

  return (
    <CellWrapper cell={cell} styles={outerStyles} width={width}>
      <div className={styleList([baseWrapper])}>
        <RegressionSpark cell={cell} currency={currency} />
      </div>
    </CellWrapper>
  );
};

export const DemandSparkCell = ({
  cell,
  wrapperStyle,
  width,
}: {
  cell: any;
  wrapperStyle: string;
  width?: number;
}) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle]);
  let innerStyles = styleList([baseInner, "w-full flex  font-mono"]);

  return (
    <CellWrapper cell={cell} styles={outerStyles} width={width}>
      <div className={styleList([baseWrapper])}>
        <DemandSpark cell={cell} />
      </div>
    </CellWrapper>
  );
};

export const InventorySparkCell = ({
  cell,
  wrapperStyle,
  width,
}: {
  cell: any;
  wrapperStyle: string;
  width?: number;
}) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle]);
  let innerStyles = styleList([baseInner, "w-full flex  font-mono"]);

  return (
    <CellWrapper cell={cell} styles={outerStyles} width={width}>
      <div className={styleList([baseWrapper])}>
        <InventorySpark cell={cell} />
      </div>
    </CellWrapper>
  );
};
export const SparkCell = ({
  cell,
  wrapperStyle,
  width,
}: {
  cell: any;
  wrapperStyle: string;
  width?: number;
}) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle]);
  let innerStyles = styleList([baseInner, "w-full flex  font-mono"]);

  return (
    <CellWrapper cell={cell} styles={outerStyles} width={width}>
      <div className={styleList([baseWrapper])}>
        <DemandSpark cell={cell} />
      </div>
    </CellWrapper>
  );
};

export const RankCell = ({
  cell,
  wrapperStyle,
  width,
}: {
  cell: any;
  wrapperStyle: string;
  width?: number;
}) => {
  let outerStyles = styleList([wrapperStyle || "", tdBorderStyle]);
  let color =
    cell.getValue() < STACKED_AREA_TAILWIND.length
      ? STACKED_AREA_TAILWIND[cell.getValue() - 1].tailwindBg
      : "";
  let isOthers = cell.row.original.productTitle === "Other Products";

  if (isOthers) {
    return (
      <CellWrapper cell={cell} styles={outerStyles} width={width}>
        <div className={styleList([baseWrapper])}>
          <LegendItem title={""} legendColor={"bg-black"} area />
        </div>
      </CellWrapper>
    );
  } else {
    return (
      <CellWrapper cell={cell} styles={outerStyles} width={width}>
        <div className={styleList([baseWrapper])}>
          <LegendItem title={cell.getValue()} legendColor={color} area />
        </div>
      </CellWrapper>
    );
  }
};

export const Pulse = () => {
  return (
    <div className="p-4 max-w-sm w-full mx-auto">
      <div className="animate-pulse flex space-x-4 bg-gray-100 h-[10px] rounded"></div>
    </div>
  );
};

export const LoadingCell = ({ cell }: { cell: any }) => {
  return (
    <CellWrapper cell={cell} styles={"border-b border-r border-gray-200"}>
      <Pulse />
    </CellWrapper>
  );
};

export const SelectableCell = ({ cell }: { cell: any }) => {
  return (
    <CellWrapper cell={cell} styles={tdBorderStyle + " px-2.5"}>
      <IndeterminateCheckbox
        {...{
          checked: cell.row.getIsSelected(),
          disabled: !cell.row.getCanSelect(),
          indeterminate: cell.row.getIsSomeSelected(),
          onChange: cell.row.getToggleSelectedHandler(),
        }}
      />
    </CellWrapper>
  );
};

interface EditCellProps {
  cell: any;
  handleClick: () => void;
  percent?: number;
}

export const EditInModalCell = ({ cell, handleClick, percent }: EditCellProps) => {
  return (
    <CellWrapper cell={cell} styles={tdBorderStyle}>
      <div
        className={styleList([
          baseWrapper,
          clickable,
          "transition-all rounded-none flex group gap-2 w-full hover:bg-gray-100 rounded-md justify-between font-mono",
        ])}
        onClick={handleClick}
      >
        {cell.getValue()}
        <TableIconButton
          icon={
            <EditModal
              className="w-[16px] transition-all color-base-text opacity-50 group-hover:opacity-90"
              title="edit"
            />
          }
        />
        {/* {percent && <PercentPill percent={percent} />} */}
      </div>
    </CellWrapper>
  );
};

export const PercentPill = ({ percent }: { percent: number }) => {
  let variance = 0.1;
  let color = "red";
  if (percent <= variance && percent >= variance * -1) {
    color = "gray";
  } else if (percent > 0.05) {
    color = "#0b8036";
  }

  return (
    <p
      style={{ color: color }}
      className="font-mono rounded-md bg-gray-100 text-2xs h-full w-full flex items-center justify-center"
    >
      {formatNumber(percent * 100, "percentFloat")}
    </p>
  );
};

// interface NotDefinedCellProps extends DefaultCellProps {
//   link?: string;
// }

// // this is for cells that have a in-app value that is not set
// // should all have links to the place where they can be defined
// export const NotDefinedCell = ({ value, link }: NotDefinedCellProps) => {
//   if (link) {
//     let styles = styleList([baseWrapper, clickable, "group justify-between text-gray-500"]);
//     let innerBase = "rounded-md px-1 py-1 shrink truncate";
//     let innerStyles =
//       "group-hover:text-purple-base transition-all underline-offset-4 cursor-pointer" +
//       " " +
//       innerBase;

//     const navigate = useNavigate();

//     return (
//       <div
//         className={styles}
//         onClick={() => {
//           navigate(link);
//         }}
//       >
//         <p className={innerStyles}>{value}</p>
//       </div>
//     );
//   } else {
//     let styles = styleList([baseWrapper, "text-gray-500"]);
//     return (
//       <div className={styles}>
//         <p className={baseInner}>{value}</p>
//       </div>
//     );
//   }
// };

// // this is for text and a pill
// // maybe we pass the pill in as a child
// export const PillCell = ({ value, pillValue }: { value: string; pillValue?: string }) => {
//   let styles = styleList([baseWrapper, "group justify-between"]);
//   let countPillStyles =
//     "border border-internal text-gray-400 inline-block bg-base-lightwhite rounded-full px-2.5 text-xs shadow-xs";
//   return (
//     <div className={styles}>
//       <p className={baseInner}>{value}</p>
//       {pillValue ? <span className={countPillStyles}>{pillValue}</span> : null}
//     </div>
//   );
// };

// // some duplications in DetailCell
// export const Pill = ({ value }: { value: string }) => {
//   let countPillStyles =
//     "border border-internal text-gray-400 inline-block bg-base-lightwhite rounded-full px-2.5 text-xs shadow-xs";
//   return <span className={countPillStyles}>{value}</span>;
// };

// export const BlankCell = (props: any) => {
//   let styles = styleList([baseWrapper]);
//   return <div className={styles}></div>;
// };

// export const SelectionCell = ({ row }: { row: Row }) => {
//   let styles = styleList([baseWrapper, "justify-center"]);

//   return (
//     <div className={styles}>
//       <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
//     </div>
//   );
// };
