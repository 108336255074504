import { lazy, FC } from "react";
import TableLayout, { AdminLayoutProps } from "./layouts/AdminLayout";
import AuthLayout from "layouts/AuthLayout";
import BaseLayout from "layouts/BaseLayout";

interface IRoute {
  path: string;
  layout?: FC<AdminLayoutProps>;
  component: FC;
}

export const allRoutes: IRoute[] = [
  {
    layout: BaseLayout,
    path: "/",
    component: lazy(() => {
      return import("./pages/dashboard");
    }),
  },
  {
    layout: BaseLayout,
    path: "/dashboard",
    component: lazy(() => {
      return import("./pages/dashboard");
    }),
  },
  {
    layout: AuthLayout,
    path: "/payment-information",
    component: lazy(() => {
      return import("./pages/payment-information");
    }),
  },
  {
    layout: AuthLayout,
    path: "/data-pull",
    component: lazy(() => {
      return import("./pages/data-pull");
    }),
  },
  {
    layout: AuthLayout,
    path: "/walkthrough",
    component: lazy(() => {
      return import("./pages/walkthrough");
    }),
  },
  {
    layout: TableLayout,
    path: "/demand",
    component: lazy(() => import("./pages/demand")),
  },
  {
    layout: TableLayout,
    path: "/demand/override",
    component: lazy(() => import("./pages/demand-override")),
  },
  {
    layout: TableLayout,
    path: "/top-performers",
    component: lazy(() => import("./pages/top-performers")),
  },
  {
    layout: TableLayout,
    path: "/inventory",
    component: lazy(() => import("./pages/inventory")),
  },
  {
    layout: TableLayout,
    path: "/purchase-planning",
    component: lazy(() => import("./pages/purchase-planning")),
  },
  {
    layout: TableLayout,
    path: "/purchase-planning/summary",
    component: lazy(() => import("./pages/purchase-planning/summary")),
  },
  {
    layout: TableLayout,
    path: "/products",
    component: lazy(() => import("./pages/products")),
  },
  {
    layout: BaseLayout,
    path: "/alerts",
    component: lazy(() => {
      return import("./pages/alerts");
    }),
  },
  {
    layout: BaseLayout,
    path: "/data-sources",
    component: lazy(() => import("./pages/data-sources")),
  },
  {
    layout: BaseLayout,
    path: "/help",
    component: lazy(() => import("./pages/help")),
  },
  {
    layout: BaseLayout,
    path: "/account",
    component: lazy(() => import("./pages/account/")),
  },
  // logged out
  {
    path: "/login",
    layout: AuthLayout,
    component: lazy(() => import("./pages/auth/login")),
  },
  {
    layout: AuthLayout,
    path: "/set-password",
    component: lazy(() => import("./pages/auth/set-password")),
  },
  {
    layout: AuthLayout,
    path: "/reset",
    component: lazy(() => import("./pages/auth/forgot-password")),
  },

  // admin
  {
    layout: BaseLayout,
    path: "/admin",
    component: lazy(() => import("./pages/admin/admin")),
  },

  // hidden
  {
    layout: BaseLayout,
    path: "/style-guide",
    component: lazy(() => import("./pages/style-guide")),
  },
  {
    layout: AuthLayout,
    path: "/app",
    component: lazy(() => {
      return import("./pages/install");
    }),
  },
  {
    layout: AuthLayout,
    path: "/auth/",
    component: lazy(() => {
      return import("./pages/install");
    }),
  },
  {
    layout: AuthLayout,
    path: "/auth/offline-callback",
    component: lazy(() => {
      return import("./pages/install");
    }),
  },
  {
    layout: AuthLayout,
    path: "/auth/callback",
    component: lazy(() => {
      return import("./pages/install");
    }),
  },
  {
    layout: AuthLayout,
    path: "/auth/amazon-seller-central",
    component: lazy(() => {
      return import("./pages/auth/amazon-seller-central");
    }),
  },
  {
    layout: AuthLayout,
    path: "/auth/quickbooks",
    component: lazy(() => {
      return import("./pages/auth/quickbooks");
    }),
  },
  // errors
  {
    layout: AuthLayout,
    path: "/*",
    component: lazy(() => import("./pages/err404")),
  },
];
