import { useContext, useState, useEffect } from "react";
import { Tooltip, Legend, XAxis, YAxis, BarChart, Bar, ResponsiveContainer, Cell } from "recharts";
import { DashboardContext, AppStateContext, ModalContext } from "context";
import {
  formatCurrency,
  formatPercent,
  decimalToPercent,
  formatNumber,
  formatCurrencyRate,
} from "utils/helper-ts";
import { BaseTooltip, TooltipItem } from "components/ChartTooltip";
import { PerformanceChartProps, TickProps, BarData } from "./local-types";
import { HEX_COLORS, TW_COLORS } from "utils/chart";
import { ChartLegend, LegendItem } from "components/ChartLegend";
import { ChartLoadingWrapper } from "components/Loading";

const formatTick = ({ tick, criteria, currency, props }: TickProps): string => {
  if (typeof tick === "number") {
    switch (criteria.key) {
      case "grossing":
        return formatNumber(tick, "currencyCompact", currency);
      case "percent-revenue":
        return formatNumber(tick, "formatPercentWhole");
      case "rate-sales":
        return formatNumber(tick, "currencyCompact", currency);
      case "days-wo-sales":
        return formatNumber(tick, "rate", "", "days");
      case "lowest-margins":
        return formatNumber(tick, "decimalToPercentWhole");
      default:
        return formatNumber(tick);
    }
  } else {
    let maxChar = 30;
    if (tick) {
      if (typeof tick === "string" && tick.length > maxChar) {
        return `${tick.slice(0, maxChar)}...`;
      }
      return tick.toString();
    }
    return "Unknown";
  }
};

export const PerformanceChart = ({
  data: performers,
  criteria,
  barColor,
  option,
  loading,
  isVertical,
  type,
  organizeBy,
  goToItem,
  dynamicHeight,
}: PerformanceChartProps) => {
  const { user } = useContext(AppStateContext);
  const { OpenModal } = useContext(ModalContext);

  const { topPerfOption, bottomPerfOption } = useContext(DashboardContext);
  const [currency, setCurrency] = useState(user.currency);
  const [barSize, setBarSize] = useState(40);
  const [pid, setPid] = useState("");
  const [vid, setVid] = useState("");
  const [category, setCategory] = useState("");
  const [focusBar, setFocusBar] = useState(-1);
  const [mouseLeave, setMouseLeave] = useState(true);
  const [legendTitle, setLegendTitle] = useState(criteria.name || criteria.value);
  const colors = {
    text: "#180A30",
    borderInternal: "#E4E0F7",
    background: "#F9F9FD",
    demandChart: {
      baseBar: "rgba(199, 227, 177, 1)",
      baseTailwind: "valence-positive",
      hoverBar: "rgba(199, 227, 177, 0.5)",
    },
    topPerformers: {
      baseBar: "rgba(199, 227, 177, 1)",
      baseTailwind: "valence-positive",
      hoverBar: "rgba(199, 227, 177, 0.5)",
    },
    bottomPerformers: {
      baseBar: "rgba(180, 205, 242, 1)",
      baseTailwind: "valence-negative",
      hoverBar: "rgba(180, 205, 242, 0.5)",
    },
  };

  const isVariant =
    (type === "topPerformers" &&
      (topPerfOption.key === "variant" || topPerfOption.key === "sku")) ||
    (type === "bottomPerformers" &&
      (bottomPerfOption.key === "variant" || bottomPerfOption.key === "sku"));

  useEffect(() => {
    if (performers.length < 15) {
      setBarSize(25);
    } else {
      setBarSize(15);
    }
  }, [performers]);
  
  useEffect(() => {
    setLegendTitle(criteria.name || criteria.value);
  }, [criteria]);

  useEffect(() => {
    setCurrency(user.currency);
  }, [user]);

  return (
    <div
      style={{ width: "100%", height: !loading || dynamicHeight ? "100%" : 500 }}
      className={`bg-base-lightwhite rounded-[10px] shadow-dashboard border border-solid border-border-internal p-4 `}
    >
      <ChartLoadingWrapper data={performers} loading={loading}>
        <ResponsiveContainer
          width="99%"
          debounce={1}
          minHeight={performers.length * barSize < 450 ? 450 : performers.length * barSize}
        >
          <BarChart
            data={performers}
            margin={{
              top: 16,
              right: 6,
              bottom: 2,
              left: 0,
            }}
            layout={isVertical ? "vertical" : "horizontal"}
            onMouseMove={(state) => {
              if (state.isTooltipActive && typeof state.activeTooltipIndex === "number") {
                setFocusBar(state.activeTooltipIndex);
                setMouseLeave(false);
              } else {
                setFocusBar(0);
                setMouseLeave(true);
              }
            }}
            barSize={barSize}
            onMouseLeave={(state) => setMouseLeave(true)}
            {...{
              overflow: "visible",
            }}
          >
            <XAxis
              style={{
                fontSize: 10,
                fontFamily: "SF Mono",
                fill: colors.text,
                overflowWrap: isVertical ? "normal" : "break-word",
              }}
              dataKey={isVertical ? undefined : "name"}
              type={isVertical ? "number" : "category"}
              tickFormatter={(tick, props) => formatTick({ tick, criteria, currency, props })}
              tickLine={false}
              axisLine={{ stroke: colors.borderInternal }}
              interval={0}
              angle={-45}
              textAnchor="end"
              height={60}
              // domain={[0,300]} // can make dynamic to fix bug where highest bar is lower than top of domain
            />

            <YAxis
              style={{
                fontSize: 10,
                fontFamily: "SF Mono",
                fill: colors.text,
                overflowWrap: isVertical ? "break-word" : "normal",
              }}
              width={isVertical ? 200 : 60}
              dataKey={isVertical ? "name" : undefined}
              type={isVertical ? "category" : "number"}
              interval={0}
              tickFormatter={(tick, props) => {
                return formatTick({ tick, criteria, currency, props });
              }}
              // tick={isVertical && type === "demandChart" ? <CustomizedTick /> : undefined}
              tickLine={false}
              axisLine={{ stroke: colors.borderInternal }}
            />
            <Tooltip
              content={
                <CustomizedTooltip
                  type={type}
                  setPid={setPid}
                  setVid={setVid}
                  option={option}
                  currency={user.currency}
                  metric={legendTitle}
                />
              }
              active={performers.length>0}
              cursor={{ fill: colors.background }}
            />
            <Legend
              content={
                <ChartLegend>
                  <LegendItem title={legendTitle} legendColor={TW_COLORS[type]} area />
                </ChartLegend>
              }
            />
            <Bar
              className={"hover:cursor-pointer hover:bg-black"}
              background={{ fill: "transparent" }}
              onClick={(bar) => {
                if (option.key === "category") {
                  OpenModal("category", undefined, bar.name);
                } else if (option.key === "product") {
                  OpenModal("product", undefined, undefined, bar.productId);
                } else if (option.key === "variant" || option.key === "sku") {
                  OpenModal("variant", undefined, undefined, undefined, bar.variantId);
                } else if (option.key === "vendor") {
                  OpenModal(
                    "vendor",
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    bar.vendor
                  );
                }
              }}
              dataKey="value"
              fill={barColor}
              name={criteria.name}
              radius={isVertical ? [0, 10, 10, 0] : [10, 10, 0, 0]}
            >
              {(performers || []).map((performer, index) => {
                if (focusBar === -1) {
                  return <Cell key={performer.name + index} fill={colors[type].baseBar} />;
                } else {
                  return (
                    <Cell
                      key={performer.name + index}
                      fill={
                        focusBar === index || mouseLeave
                          ? colors[type].baseBar
                          : colors[type].hoverBar
                      }
                    />
                  );
                }
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </ChartLoadingWrapper>
    </div>
  );
};

export default PerformanceChart;

// this has to be any because the api isn't consistent enough right now, we need to fix this at the root
const CustomizedTooltip = (props: any) => {
  let { active, payload, label, option, currency, metric } = props;
  if (active && payload?.length) {
    let data = payload[0].payload;
    let labelType = option.key;

    if (option.key === "variant" || option.key === "sku") {
      let pTitle = data.pTitle || ""; // this should never be undefined because it needs to be part of Variant

      return (
        <BaseTooltip
          title={`Product: ${pTitle}`}
          subTitle={`${labelType}: ${label}`}
          clickAction="click bar for details"
        >
          <InnerPerformanceTooltip data={payload[0].payload} currency={currency} metric={metric} />
          {labelType === "variant" && data.SKUs ? (
            <TooltipItem metric={"SKU"} value={formatNumber(data.SKUs[0]) || "unknown"} />
          ) : null}
        </BaseTooltip>
      );
    } else {
      return (
        <BaseTooltip title={`${label}`} clickAction="click bar for details">
          <InnerPerformanceTooltip data={payload[0].payload} currency={currency} metric={metric} />
          {data.SKUs ? (
            <TooltipItem
              metric={"Number of SKUs"}
              value={formatNumber(data.SKUs.length) || "unknown"}
            />
          ) : null}
        </BaseTooltip>
      );
    }
  }
  return null;
};

const InnerPerformanceTooltip = ({
  data,
  currency,
  metric,
}: {
  data: BarData;
  currency: string;
  metric: string;
}) => {
  return (
    <>
      <TooltipItem metric={metric} value={metricChooser(data.value, metric, currency)} />
      <TooltipItem
        metric={"Unit Price"}
        value={data.unitPrice ? `${formatCurrency(data.unitPrice, currency)}` : "unknown"}
      />
      <TooltipItem
        metric={"Unit Cost"}
        value={data.unitCost ? `${formatCurrency(data.unitCost, currency)}` : "unknown"}
      />
    </>
  );
};

// need updates on the backend for this to be less wild
const metricChooser = (value: number, name: string, currency: string) => {
  if (value && value !== null) {
    if (name === "Gross Revenue") {
      return formatCurrency(value, currency);
    } else if (name == "Rate of Sales") {
      return formatCurrencyRate(value, currency, "day");
    } else if (name === "Percent of Revenue") {
      return formatPercent(value);
    } else if (name === "Percent Margin") {
      return decimalToPercent(value);
    } else if (name === "Days Without Sales") {
      return formatNumber(value);
    } else {
      return "unknown";
    }
  } else {
    return "unknown";
  }
};
