import { ReactNode } from "react";
import { MetricModalTitle } from "components/Modal/metricModal";

interface AreaTopBarProps {
  areaName: string;
  children?: ReactNode;
  className?: string;
  hug?: boolean;
}

const AreaTopBar = ({ areaName, children, className, hug }: AreaTopBarProps) => {
  return (
    <div
      className={`flex flex-wrap ${
        hug ? "" : "justify-between"
      } text-lg items-center relative mb-3 gap-2 ${className}`}
    >
      <div className="text-base-text font-medium">
        <MetricModalTitle title={areaName} />
      </div>
      <div className="flex gap-3">{children}</div>
    </div>
  );
};

export default AreaTopBar;
