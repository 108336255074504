import { UnitLoadingWrapper } from "components/Loading";
import { AsyncStatus } from "types";

interface Item {
  name: string;
  type: "category" | "product" | "variant" | "location" | "vendor";
}

interface BreadCrumbProps {
  data: Item[];
  onClick?: (id: string) => void;
  large?:boolean;
  loading: AsyncStatus;
}

// TODO: it is for details modal. need to update it later if we need the page level
export const BreadCrumb = ({ 
  data, 
  onClick, 
  loading,
  large,
}: BreadCrumbProps) => {
  let lastIdx = data.length-1
  return (
    <div className="flex">
      <UnitLoadingWrapper loading={loading}>
        {data.map((item, idx) => (
          <div key={item.type} className={`font-mono ${large? "text-lg":"text-sm"} flex ${idx === lastIdx?"font-mono-extrabold":"font-mono-medium"}`}>
            <span
              className="transition-all cursor-pointer text-base-text underline hover:text-purple-base"
              onClick={() => onClick?.(item.type)}
            >
              {item.name}
            </span>
            <div className="font-mono-bold px-1">{idx !== data.length - 1 ? "/" : ""}</div>
          </div>
        ))}
      </UnitLoadingWrapper>
    </div>
  );
};

export default BreadCrumb;
