import { ReactElement, useEffect, useState, useContext } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import SideBarItem from "./SideBarItem";
import { ReactComponent as Users } from "assets/images/users.svg";
import { ReactComponent as Database } from "assets/images/database.svg";
import { ReactComponent as FullLogo } from "assets/images/full_logo.svg";
import { ReactComponent as IconLogo } from "assets/images/icon_logo.svg";
import { ReactComponent as BarChart } from "assets/images/bar-chart.svg";
import { ReactComponent as Education } from "assets/images/education.svg";
import { ReactComponent as Growth } from "assets/images/growth.svg";
import { ReactComponent as Champion } from "assets/images/champion.svg";
import { ReactComponent as PurchaseList } from "assets/images/paper-list.svg";
import { ReactComponent as Products } from "assets/images/products.svg";
import { ReactComponent as Shop } from "assets/images/shop.svg";
import { ReactComponent as Clock } from "assets/images/clock.svg";
import { ReactComponent as Help } from "assets/images/help.svg";
import { ReactComponent as Settings } from "assets/images/settings.svg";
import { ReactComponent as Account } from "assets/images/account.svg";
import { ReactComponent as Summary } from "assets/images/summary.svg";
import { ReactComponent as Arrow } from "assets/images/collapse-arrow.svg";
import { ReactComponent as LogOut } from "assets/images/logout.svg";
import { ReactComponent as Calendar } from "assets/images/calendar-w.svg";
import { ReactComponent as Chat } from "assets/images/chat.svg";
import { useWindowSize } from "hooks/useWindowSize";
import { brandGradient, errorHandler } from "utils/helper-ts";
import { AppStateContext, RequestContext } from "context";
import client from "api";

export interface ISBRoute {
  name: string;
  to: string;
  icon: ReactElement;
  hasNotification?: boolean;
  subtext?: string;
  collapsed?: boolean;
  disabled?: boolean;
  hide?: boolean;
  firstLogin?: boolean;
  new?: boolean;
  routeUnavailable?: boolean;
}

interface IPermission {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  role_id: string;
}

const SideBar = ({ collapsed, close }: { collapsed: boolean; close: () => void }) => {
  const { pendingRequests } = useContext(RequestContext);
  const { user, permissions, updatePermissions, firstLogin, updateFirstLogin } =
    useContext(AppStateContext);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const [disableButtons, setDisableButtons] = useState<boolean>(false);

  const isNewUser = async () => {
    // not in use right now
    try {
      const res = await client.get(`/inventory-onboard`);
      updateFirstLogin(res.data.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  const getPermissions = async () => {
    try {
      setLoading(true);
      const res = await client.get("/permissions");
      updatePermissions(res.data.map((perm: IPermission) => perm.name).join(" , "));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (permissions.length === 0) {
      getPermissions();
    }
  }, [location]);

  useEffect(() => {
    setDisableButtons(pendingRequests > 4);
  }, [pendingRequests]);

  const primaryRoutes: ISBRoute[] = [
    {
      name: "Summary",
      to: "/dashboard",
      icon: <Summary className="mx-2.5" />,
      disabled: disableButtons,
      collapsed: collapsed,
    },
    {
      name: "Inventory",
      to: "/inventory",
      icon: <Education className="mx-2.5" />,
      disabled: disableButtons,
      hide: false,
      collapsed: collapsed,
    },
    {
      name: "Demand",
      to: "/demand",
      icon: <Growth className="mx-2.5" />,
      disabled: disableButtons,
      hide: false,
      collapsed: collapsed,
    },
    {
      name: "Purchase Recs",
      to: "/purchase-planning",
      icon: <PurchaseList className="mx-2.5" />,
      disabled: disableButtons,
      hide: false,
      collapsed: collapsed,
      new: false,
    },
    // {
    //   name: "Purchase Rec Summary",
    //   to: "/purchase-planning/summary",
    //   icon: <Calendar className="mx-2.5" />,
    //   disabled: disableButtons,
    //   hide: false,
    //   collapsed: collapsed,
    //   new: false,
    // },
    {
      name: "Top Performers",
      to: "/top-performers",
      icon: <Champion className="mx-2.5" />,
      disabled: false,
      hide: false,
      collapsed: collapsed,
    },
    {
      name: "Alerts",
      to: "/alerts",
      icon: <Clock className="mx-2.5" />,
      disabled: disableButtons,
      hide: false,
      collapsed: collapsed,
    },

    {
      name: "Products",
      to: "/products",
      icon: <Products className="mx-2.5" />,
      disabled: disableButtons,
      hide: false,
      collapsed: collapsed,
    },
  ];

  const secondaryRoutes: ISBRoute[] = [
    {
      name: "Chat",
      to: "/chat",
      icon: <Chat className="mx-2.5" />,
      disabled: !permissions.includes("GodMode"),
      hide: !permissions.includes("GodMode"),
      collapsed: collapsed,
      routeUnavailable: true,
    },
    {
      name: "Admin",
      to: "/admin",
      icon: <Database className="mx-2.5" />,
      disabled: !permissions.includes("GodMode"),
      hide: !permissions.includes("GodMode"),
      collapsed: collapsed,
    },
    {
      name: "Data Sources",
      to: "/data-sources",
      icon: <Database className="mx-2.5" />,
      disabled: false,
      hide: false,
      collapsed: collapsed,
      new: false,
    },
    // {
    //   name: "Help & Education",
    //   to: "/help",
    //   icon: <Help className="mx-2.5" />,
    //   collapsed: collapsed,
    //   // hasNotification: true,
    //   // firstLogin: true, // I'm dictating this for now so that even returning users see it
    //   disabled: disableButtons,
    // },
    {
      name: user.name,
      to: "/account",
      icon: <Account className="mx-2.5" />,
      collapsed: collapsed,
      subtext: user.shopifyShopId || "no store selected",
      disabled: disableButtons,
    },
  ];

  return (
    <div
      className={`${collapsed ? "w-top-bar" : "w-left-nav"}
       z-[1001] fixed h-full border-r border-solid border-border-internal`}
    >
      <NavLink
        to="/dashboard"
        style={brandGradient}
        className={`${collapsed ? "px-3 justify-center" : "px-6 transition-all"}
          w-full h-top-bar flex items-center`}
      >
        {collapsed ? <IconLogo /> : <FullLogo />}
      </NavLink>
      <div
        className={`px-3 py-6 flex flex-col h-[calc(100%-theme(height.top-bar))] justify-between`}
      >
        <ul>
          {primaryRoutes.map(
            (route: ISBRoute) => !route.hide && <SideBarItem {...route} key={route.to} />
          )}
        </ul>
        <div>
          <ul>
            {secondaryRoutes.map(
              (route: ISBRoute) => !route.hide && <SideBarItem {...route} key={route.to} />
            )}
          </ul>
          {/* <div className={`w-full flex  ${collapsed ? "justify-center" : "justify-start"}`}>
            <button
              className={`${collapsed ? "rotate-180" : ""} 
              text-base-inverted p-2 transition-all rounded-full hover:bg-highlight-gentle`}
              onClick={close}
            >
              {collapsed ? <Arrow /> : <Arrow />}
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
