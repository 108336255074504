import { RowSpanWrapper, DetailsCell, MetricCell, LoadingCell, ImageCell } from "components/Table";
import { Cell } from "@tanstack/react-table";
import {
  historicalDemandMetric,
  currentDemandMetric,
  forecastDemandMetric,
} from "./custom-row-map";
import {

  rowGroupingBorder,
} from "utils/helper-ts";
import { AsyncStatus } from "types";

export const DemandCellMap = ({
  cell,
  currency,
  spannedRows,
  loading,
  editable,
}: {
  cell: Cell<any, any>;
  currency: string;
  spannedRows: number;
  loading: AsyncStatus;
  editable: boolean;
}) => {
  const { grouping, organizeBy, productId, productTitle, variantIdCount, variantId, metric } =
    cell.row.original;
  const accessorKey = (cell.column.columnDef as { accessorKey: string }).accessorKey;
  let border = spannedRows <= 1 ? "border-b border-r" : rowGroupingBorder; // no special row line when no spanned rows

  if (loading === AsyncStatus.Loading) {
    return <LoadingCell cell={cell} />;
  }

  switch (
    accessorKey // predefined cases
  ) {
    case "grouping":
      return editable ? (
        <td key={cell.id} className={border}>
          <DetailsCell
            cell={cell}
            title={grouping}
            id={productId}
            vid={variantId}
            organizeBy={organizeBy}
            variants={variantIdCount}
            productTitle={productTitle}
            spannedRows={spannedRows}
          />
        </td>
      ) : (
        <RowSpanWrapper cell={cell} styles={`absolute bg-white ${border}`} span={spannedRows}>
          <DetailsCell
            cell={cell}
            title={grouping}
            id={productId}
            vid={variantId}
            organizeBy={organizeBy}
            variants={variantIdCount}
            productTitle={productTitle}
            spannedRows={spannedRows}
          />
        </RowSpanWrapper>
      );

    case "metric":
      return <MetricCell cell={cell} />;
    default:
      switch (
        true // regex for flexible columns // found out these are better as true components than functions
      ) {
        case /forecast$/.test(accessorKey):
          return forecastDemandMetric(cell, metric, accessorKey, currency, editable);
        case /current$/.test(accessorKey):
          return currentDemandMetric(cell, metric, accessorKey, currency);
        case /current_forecast/.test(accessorKey):
          return currentDemandMetric(cell, metric, accessorKey, currency);
        case /historical/.test(accessorKey):
          return historicalDemandMetric(cell, metric, accessorKey, currency);
        default:
          return null;
      }
  }
};
