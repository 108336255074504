import React, { ButtonHTMLAttributes, useEffect, useMemo, useState } from "react";
import { ReactComponent as LoadingIcon } from "assets/images/loading.svg";
import { ReactComponent as LeftArrow } from "assets/images/left-arrow.svg";
import { ReactComponent as Close } from "assets/images/close.svg";
import { AsyncStatus } from "types";
import { ReactComponent as BarChart } from "assets/images/bar-chart.svg";
import { ReactComponent as DownloadSVG } from "assets/images/download.svg";
import { ReactComponent as Copy } from "assets/images/copy.svg";
import CsvDownloader from "react-csv-downloader";
import { ReactComponent as DownloadSVG2 } from "assets/images/download2.svg";
import { toast } from "react-toastify";
import { PillCallout } from "components/Pill";
import { InventoryContext } from "context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { openInNewTab } from "utils/helper-ts";
import { AppStateContext } from "context";
import { ReactComponent as OutLink } from "assets/images/up-to-right.svg";

export type BUTTON_TYPE = "primary" | "secondary";
type BUTTON_SIZE = "small" | "large";

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  buttonType: BUTTON_TYPE;
  size?: BUTTON_SIZE;
  backgroundColor?: string;
  hoverColor?: string;
  borderColor?: string;
  disabled?: boolean;
  children?: any;
  /* small button ? 13 * 13 : 21 * 21
   ** TODO! update loading & icon button styles later
   */
  icon?: any;
  rightIcon?: any;

  className?: string;
  loading?: AsyncStatus;
}

interface ButtonProps {
  color: string;
  border: string;
  hover: string;
}

export const Button = ({
  label,
  buttonType,
  size = "small",
  backgroundColor,
  borderColor,
  hoverColor,
  disabled,
  icon,
  rightIcon,
  className,
  loading,
  children,
  onClick,
  style,
  ...props
}: IButton) => {
  const [hovered, setHovered] = useState<boolean>(false);

  const isPrimaryButton: boolean = useMemo(() => {
    return buttonType === "primary";
  }, [buttonType]);

  const primaryButtonProps: ButtonProps = useMemo(() => {
    return {
      color: backgroundColor || "#7047EB",
      border: "transparent",
      hover: hoverColor || "#593eab",
    };
  }, [backgroundColor, hoverColor]);

  const secondaryButtonProps: ButtonProps = useMemo(() => {
    return {
      color: backgroundColor || "#EFEDF5",
      border: borderColor || "transparent",
      hover: hoverColor || "#447CE9",
    };
  }, [borderColor, hoverColor, backgroundColor]);

  const buttonProps: ButtonProps = useMemo(() => {
    if (isPrimaryButton) {
      return primaryButtonProps;
    } else {
      return secondaryButtonProps;
    }
  }, [isPrimaryButton, primaryButtonProps, secondaryButtonProps]);

  const hoveredStyles: React.CSSProperties = useMemo(() => {
    return isPrimaryButton
      ? {
          backgroundColor: buttonProps.hover,
          borderColor: buttonProps.hover,
        }
      : {
          backgroundColor: buttonProps.color,
          color: buttonProps.hover,
          borderColor: buttonProps.hover,
        };
  }, [buttonProps, isPrimaryButton]);

  const normalStyles: React.CSSProperties = useMemo(() => {
    return {
      backgroundColor: buttonProps.color,
      borderColor: buttonProps.border,
    };
  }, [buttonProps]);

  return (
    <button
      disabled={disabled}
      type="button"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={`
        flex items-center cursor-pointer border border-solid rounded gap-2
        disabled:bg-neutral-100 disabled:text-black/25 disabled:border-neutral-300 disabled:cursor-default
        transition-all ease-in-out duration-250
        ${size === "large" ? "px-2 py-1 text-sm h-10" : "px-2 py-1 text-xs h-8"}
        ${isPrimaryButton ? "text-base-inverted" : "text-base-textmedium"}
        ${className ? className : ""}
      `}
      style={
        disabled ? {} : hovered ? { ...style, ...hoveredStyles } : { ...style, ...normalStyles }
      }
      onClick={onClick}
      {...props}
    >
      {(loading === AsyncStatus.Loading || icon) && (
        <span>
          {loading === AsyncStatus.Loading ? (
            <LoadingIcon
              className={`${size === "small" ? "h-[13px] w-[13px]" : "h-[21px] w-[21px]"}`}
            />
          ) : (
            icon
          )}
        </span>
      )}

      {children ? children : <span className="m-auto">{label}</span>}
      {rightIcon ? rightIcon : <></>}
    </button>
  );
};

export default Button;


export const TableButton = ({
  label,
  icon,
  loading,
  children,
  active,
  onClick,
}: {
  label?:string;
  icon?:React.ReactNode;
  onClick?: () => void;
  active?:boolean;
  loading?: AsyncStatus;
  children?: React.ReactNode;
}) => {
  let baseStyle = "bg-gray-100 hover:border-gray-300 border font-mono border-gray-200 transition-all text-xs flex w-fit gap-2 h-8 py-1 px-2 rounded-full justify-center items-center "
  let activeStyle = baseStyle + " " + "bg-gray-200"
  return (
    <button 
      className={active? activeStyle : baseStyle}
      onClick={onClick}
      >
      {children}
      {label}
      {icon}
    </button>
  )
}

export const TableIconButton = ({
  icon,
  active,
  onClick,
}: {
  icon?:React.ReactNode;
  onClick?: () => void;
  active?:boolean;
  loading?: AsyncStatus;
  children?: React.ReactNode;
}) => {

  let baseStyle = "bg-gray-100 hover:border-gray-300 focus:border-black group-focus border border-gray-200 transition-all flex w-fit gap-2 h-8 w-8 p-2 rounded-full justify-center items-center "
  let activeStyle = baseStyle + " " + "bg-gray-200"
  return (
    <button 
      className={active? activeStyle : baseStyle}
      onClick={onClick}
      >
      {icon}
    </button>
  )
}

export const CopyButton = ({
  copyText
}: {
  copyText?:string;
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [copyError, setCopyError] = useState(false);


  async function copyTextToClipboard(text: string) {
    return await navigator.clipboard.writeText(text);
  }

  const handleCopyClick = (copyText:string) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        setCopyError(true);
        setTimeout(() => {
          setCopyError(false);
        }, 1500);
      });
  }

    return <div className="relative">
      <TableIconButton
        onClick={() =>  handleCopyClick(copyText || "no copy")}
        icon={<Copy className="w-[16px] transition-all opacity-50 group-hover:opacity-90"/>}
      />
      {isCopied?
        <PillCallout position="top-right" color="dark">Copied!</PillCallout>
        :
      null}
      {copyError?
        <PillCallout position="top-right" color="dark">Failed</PillCallout>
        :
      null}
    </div>
}



export const PlotButton = ({
  disabled,
  onClick,
  label,
  active,
}: {
  disabled: boolean;
  onClick: () => void;
  label: string;
  active: boolean;
}) => {
  return <TableButton
    active={active}
    label={label}
    onClick={onClick}
    icon={<BarChart className="w-[14px] opacity-50 group-hover:opacity-90"/>}
  />
}

// I don't have time to refactor everything so I'm just going to wrap these buttons in a named component
// when someone gets the chance just start to remove the use of the monolithic button component above

export const ModalLeftButton = ({
  disabled,
  onClick,
  label,
}: {
  disabled: boolean;
  onClick: () => void;
  label: string;
}) => {
  return (
    <Button
      buttonType="primary"
      size="large"
      label={label}
      icon={<LeftArrow />}
      onClick={onClick} // why are these different?
      disabled={disabled}
    />
  );
};

export const ModalRightButton = ({
  disabled,
  onClick,
  label,
}: {
  disabled: boolean;
  onClick: () => void;
  label: string;
}) => {
  return (
    <Button
      buttonType="primary"
      size="large"
      label={label}
      rightIcon={
        <div className="ml-1.5">
          <LeftArrow className="origin-center rotate-180" />
        </div>
      }
      onClick={() => onClick()} // why are these different?
      disabled={disabled}
    />
  );
};

export const ApplyButton = ({ disabled, onClick }: { disabled: boolean; onClick: () => void }) => {
  return (
    <button
      onClick={onClick}
      className={`mx-auto mt-[10px] flex items-center justify-center h-8 w-[calc(100%-32px)] px-3 rounded-full bg-border-hover hover:font-bold ${
        disabled ? "hover:cursor-not-allowed text-[#FFFFFF59]" : ""
      }`}
      disabled={disabled}
    >
      Apply
    </button>
  );
};


interface IconProps {
  children: React.ReactNode;
  onClick: () => void;
  backgroundColor?: string;
  backgroundHover?: string;
  iconColor?: string;
  disabled?: boolean;
}

const ICON_BUTTON_DIMENSIONS = "rounded-full p-1 transition-all"

export const SingleIconButton = ({ 
  children, 
  onClick, 
  iconColor, 
  disabled,
  backgroundColor,
  backgroundHover
}: IconProps) => {
  if (disabled) {
    return (
      <div
        className={`opacity-50
        ${
          backgroundColor? backgroundColor :"bg-base-gentle/90"
        }
        ${ICON_BUTTON_DIMENSIONS} 
        ${
          iconColor ? iconColor : "text-purple-base"
        }`}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      className={`cursor-pointer 
      ${ICON_BUTTON_DIMENSIONS} 
      ${
        iconColor ? iconColor : "text-purple-base"
      } 
      ${
        backgroundColor? backgroundColor :"bg-base-gentle/90"
      }
      ${
        backgroundHover? backgroundHover :"hover:bg-base-gentle"
      }
      
      `}
      onClick={onClick}
    >
      {children}
    </div>
  );
};


export const CloseIcon = ({
  onClick
}: {
  onClick: ()=>void;
}) => {
  return  <SingleIconButton 
    backgroundColor={"bg-red-200"} 
    backgroundHover={"hover:bg-red-300"} 
    iconColor={"text-red-700"} 
    onClick={onClick}>
    <Close />
  </SingleIconButton>
}


export const DownloadButton = ({ 
  filename, 
  getData, 
  changeIcon,
  justIcon,
}: {
  filename: string;
  getData: () => any;
  changeIcon?: boolean;
  justIcon?:boolean;
}) => {
  const [loading, setLoading] = useState(false);

  const getDataWrapper = async () => {
    try {
      setLoading(true);
      const res = await getData();
      setLoading(false);
      return res.data.rows || res.data.data.rows;
    } catch (err: any) {
      toast.error("Unable to export csv");
      setLoading(false);
      return err;
    }
  };

  return (
    <CsvDownloader filename={filename} datas={getDataWrapper}>
      {changeIcon ? (
        <DownloadSVG2 />
      ) : (
        <Button buttonType="primary" icon={ <DownloadSVG className="h-4 w-4" />} className={loading? "animate-pulse" : ""}>
          {
            justIcon?
            <div className="hidden">Download</div>
            :
            <div className="">Download</div>
          }
        </Button>
      )}
    </CsvDownloader>
  );
};


// keep this local

const LocalDataButton = ({
  text,
  handleClick
}: {
  text: string,
  handleClick: () => void
}) => (
  <button
  className="px-1 py-1 flex gap-1 justify-center text-gray-500 transition-all bg-gray-100 border border-black border-opacity-0 hover:border-opacity-20 items-center font-mono underline rounded-md group"
  onClick={handleClick}
  >
    {text}
    <OutLink 
      className="w-[16px] h-[16px] transition-all opacity-50 group-hover:opacity-90"
      title="edit"
    />
  </button>
)



export const DataQualityButton = ({
  goToShopify,
  productId,
  text,
}: {
  goToShopify?: boolean;
  productId?: string;
  text?:string;
}) => {
  const navigate = useNavigate();
  const { updateSorting } = useContext(InventoryContext);

  const handleClick = () => {
    const shop = localStorage.getItem("shop")?.replace(".myshopify.com", "");

    if (goToShopify) {
      if (productId) {
        openInNewTab(
          `https://admin.shopify.com/store/${shop}/products/${productId}/?fromInventory=true`
        );
      } else {
        openInNewTab(
          `https://admin.shopify.com/store/${shop}/products/inventory?on_hand_quantity_max=0`
        );
      }
    } else {
      updateSorting([
        {
          id: "unitsOnHand",
          desc: false,
        },
      ]);
      navigate("/inventory");
    }
  };

  return (
    <div className="flex justify-end">
      <LocalDataButton 
        handleClick={handleClick}
        text={text ? text : 
          (goToShopify ? "fix " : "show items")
        }
        />
    </div>
  );
};

export const AddCostButton = ({
  page,
  product,
  variant,
}: {
  page: string;
  product?: string;
  variant?: string;
}) => {
  const navigate = useNavigate();
  const { showAddCost } = useContext(AppStateContext);

  // bug where if you press this button twice it applies the filter twice

  return (
    <>
      {page === "dashboard" && showAddCost && (
        <div className="flex justify-end mt-4">
          <LocalDataButton
            text={"items missing cost"}
            handleClick={() => {
              navigate("/products?addCost=true");
            }}/>
        </div>
      )}
      {page !== "dashboard" && (
        <div className="flex px-2 items-center">
          <LocalDataButton
            text={"add costs"}
            handleClick={() => {
              let shop = localStorage.getItem("shop");
              if (shop) {
                window.open(
                  `https://${shop}/admin/products/${product}${
                    variant ? `/variants/${variant}` : ""
                  }`,
                  "_blank",
                  "noreferrer"
                );
              }
            }}/>
        </div>
      )}
    </>
  );
};

