import React, { useContext } from "react";
import { AsyncStatus, IObject, IOption, OrganizeByOptions } from "types";
import { Header, Column } from "@tanstack/react-table";
import { RadioDropdown, MultiselectDropdown } from "../../components/Table/dropdown";
import { DEMAND_METRICS, SORT_OPTIONS, ORGANIZE_BY_DEMAND } from "utils/constants";
import { Resizer } from "components/Table";
import {
  formatNumber,
  styleList,
  baseSpacing,
  baseWrapper,
  hoverable,
  clickable,
  tdBorderStyle,
  rowGroupingBorder,
  lastRowStyle,
  saveState,
} from "utils/helper-ts";
import { DemandContext } from "context";
import { DefaultHeaderInner, DefaultLoadingHeader } from "components/Table";
import * as t from 'utils/table-styles'

export const DemandHeaderCellMapper = ({
  header,
  render,
  currency,
  loading,
  editable,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  currency: string;
  loading: AsyncStatus;
  editable: boolean;
}) => {
  let headerName = header.column.columnDef.header;
  let typeBypass: any = header.column.columnDef;
  let accessorKey = typeBypass.accessorKey; // idk why this doesn't exist on the type
  const { current } = useContext(DemandContext);

  if (loading === AsyncStatus.Loading) {
    return <DefaultLoadingHeader header={header} render={render} currency={currency} />;
  }

  switch (
    true // regex for flexible columns
  ) {
    case /forecast/.test(accessorKey):
      return (
        <HeaderCellSwitch
          header={header}
          render={render}
          currency={currency}
          styles="text-purple-bright"
        />
      );
    case /current/.test(accessorKey):
      return (
        <HeaderCellSwitch
          header={header}
          render={render}
          currency={currency}
          scrollTo={current}
          styles="text-base-textmedium border-r-base-text"
        />
      );
    case /historical/.test(accessorKey):
      return (
        <HeaderCellSwitch
          header={header}
          render={render}
          currency={currency}
          styles="text-base-textmedium"
        />
      );
    case /grouping/.test(accessorKey):
      if (header.depth == 1 && !editable) {
        return <DropdownHeader header={header} render={render} width={300} />;
      } else {
        return (
          <HeaderCellWrapper header={header} width={header.getSize()}>
            <DefaultHeaderInner header={header} render={render} />
          </HeaderCellWrapper>
        );
      }
    case /metric/.test(accessorKey):
      if (header.depth == 1) {
        return <MetricHeader header={header} render={render} width={190} />;
      } else {
        return (
          <HeaderCellWrapper header={header}>
            <DefaultHeaderInner
              header={header}
              render={render}
              styles="text-base-textmedium font-mono-bold"
              forcedValue="Total Revenue:"
            />
          </HeaderCellWrapper>
        );
      }

    default:
      return (
        <HeaderCellWrapper header={header}>
          <DefaultHeaderInner header={header} render={render} />
        </HeaderCellWrapper>
      );
  }
};

export const HeaderCellSwitch = ({
  header,
  render,
  currency,
  styles,
  scrollTo,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  currency: string;
  styles?: string;
  scrollTo?: React.RefObject<HTMLDivElement>;
}) => {
  let headerName = header.column.columnDef.header as string;

  if (header.column.getCanSort()) {
    switch (true) {
      case /-/.test(headerName):
        return (
          <SortableHeaderCell
            header={header}
            render={render}
            scrollTo={scrollTo ? scrollTo : undefined}
            styles={styles}
          />
        );
      default:
        return (
          <TotalsHeaderCellWrapper header={header} styles={styles}>
            <CurrencyHeaderInner header={header} render={render} currency={currency} />
          </TotalsHeaderCellWrapper>
        );
    }
  } else {
    return (
      <th className={t.baseHeaderWrapper} style={{ width: header.getSize() }}>
        <div
          className={`relative flex justify-start items-center px-1 rounded-md  transition-all overflow-visible`}
        >
          <DefaultHeaderInner header={header} render={render} />
        </div>
        <Resizer header={header} />
      </th>
    );
  }
};

export const DropdownHeader = ({
  header,
  render,
  scrollTo,
  styles,
  width,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  width?: number;
  scrollTo?: React.RefObject<HTMLDivElement>;
  styles?: string;
}) => {
  const { organizeBy, updateOrganizeBy } = useContext(DemandContext);

  return (
    <th className={t.baseHeaderWrapper + " " + styles} style={{ width: width || header.getSize() }}>
      <div
        className={`relative flex justify-between items-center px-1 rounded-md  transition-all overflow-visible text-base-text`}
        ref={scrollTo}
      >
        <RadioDropdown
          type="dropdown"
          options={ORGANIZE_BY_DEMAND}
          option={organizeBy}
          onSelect={({ key, value }: IOption) => {
            updateOrganizeBy({ key, value } as OrganizeByOptions);
            saveState("demand_org", { key, value });
          }}
        >
          <DefaultHeaderInner header={header} render={render} />
        </RadioDropdown>
      </div>
    </th>
  );
};

export const SortableHeaderCell = ({
  header,
  render,
  scrollTo,
  styles,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  scrollTo?: React.RefObject<HTMLDivElement>;
  styles?: string;
}) => {
  function sortToggler(item: IObject) {
    console.log("demand sort clicked", item);
    switch (item.key) {
      case "asc":
        return header.column.toggleSorting(false);
      case "desc":
        return header.column.toggleSorting(true);
      default:
        return header.column.clearSorting();
    }
  }

  return (
    <th className={t.baseHeaderWrapper + " " + styles} style={{ width: header.getSize() }}>
      <div
        className={`relative flex justify-between items-center px-1 rounded-md  transition-all overflow-visible text-base-text`}
        ref={scrollTo}
      >
        <RadioDropdown
          type="sort"
          options={SORT_OPTIONS}
          option={{
            key: header.column.getIsSorted() || "none",
            value: header.column.getIsSorted() || "None",
          }}
          onSelect={sortToggler}
        >
          <DefaultHeaderInner header={header} render={render} />
        </RadioDropdown>
      </div>
      <Resizer header={header} />
    </th>
  );
};

export const MetricHeader = ({
  header,
  render,
  width,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  width: number;
}) => {
  const { metrics, updateMetrics } = useContext(DemandContext);

  function handleMetricSelection(item: any) {
    updateMetrics(item);
    saveState("demand_metric", item);
  }

  // header.depth is a new pattern introduced here, not terrible, but not specific to function
  return (
    <th className={t.baseHeaderWrapper} style={{ width: width || header.getSize() }}>
      <div
        className={`relative flex justify-between items-center px-1 rounded-md  transition-all overflow-visible text-base-text`}
      >
        <MultiselectDropdown
          options={DEMAND_METRICS}
          onSelect={handleMetricSelection}
          selected={metrics}
        >
          <DefaultHeaderInner header={header} render={render} forcedValue="Metrics" />
        </MultiselectDropdown>
      </div>
    </th>
  );
};

export const HeaderCellWrapper = ({
  children,
  header,
  width,
  styles,
}: {
  header: Header<Column<any>, unknown>;
  children: React.ReactNode;
  width?: number;
  styles?: string;
}) => {
  let wrapperStyle = styleList([
    t.baseHeaderWrapper,
    styles || "",
    header.depth === 2 ? "bg-highlight-gentle" : "",
  ]);

  return (
    <th className={wrapperStyle} style={{ width: width || header.getSize() }}>
      <div className={`flex items-center px-1 rounded-md truncate`}>{children}</div>
      <Resizer header={header} />
    </th>
  );
};

export const StickyHeaderCellWrapper = ({
  children,
  header,
}: {
  header: Header<Column<any>, unknown>;
  children: React.ReactNode;
}) => {
  return (
    <th
      className={t.baseHeaderWrapper + " " + "sticky left-0 bg-base-gentle z-10"}
      style={{ width: header.getSize() }}
    >
      <div className={`flex items-center px-1 rounded-md truncate`}>{children}</div>
    </th>
  );
};
export const TotalsHeaderCellWrapper = ({
  children,
  header,
  styles,
}: {
  header: Header<Column<any>, unknown>;
  children: React.ReactNode;
  styles?: string;
}) => {
  let wrapperStyle = styleList([
    t.baseHeaderWrapper,
    styles || "",
    "bg-highlight-gentle",
    "font-mono-bold",
  ]);
  return (
    <th className={wrapperStyle} style={{ width: header.getSize() }}>
      <div className={`flex justify-center items-center px-1 rounded-md truncate`}>{children}</div>
      <Resizer header={header} />
    </th>
  );
};

export const CurrencyHeaderInner = ({
  header,
  render,
  currency,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  currency: string;
}) => {
  // because backend is wrong, should always be string, or better yet always number
  if (typeof header.column.columnDef.header === "string") {
    let value = parseInt(header.column.columnDef.header);
    return (
      <div className="px-1.5 py-1 rounded-md ">
        <p className={t.baseInner}>{formatNumber(value, "currency", currency)}</p>
      </div>
    );
  } else if (typeof header.column.columnDef.header === "number") {
    return (
      <div className="px-1.5 py-1 rounded-md ">
        <p className={t.baseInner}>
          {formatNumber(header.column.columnDef.header, "currency", currency)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};
