import { 
  BaseTooltip, 
  TooltipItem, 
  TooltipSubHeader, 
  TooltipSubItem, 
  TooltipSubItemPill } from "components/ChartTooltip";
import {
  ResponsiveContainer,
  Line,
  LineChart,
  YAxis,
  Area,
  ComposedChart,
  Tooltip
} from "recharts";
import { toLocalDateString } from "utils/date";
import {
  formatNumber,
  formatPercent
} from "utils/helper-ts";

interface ChartPoint {
  date: string;
  name: string;
  quantitySold: number;
  regression: number;
}

interface MetaData {
  slope: number;
}

const CustomizedTooltip = ( {
  active,
  payload,
  metaData,
}: {
  active?: boolean;
  payload?: Array<any>;
  metaData: MetaData
}) => {
  if (active && payload?.length) {
    const data: ChartPoint = payload[0].payload;

    let date = data.date,
      quantitySold = data.quantitySold;

    return (
      <BaseTooltip title = {`${toLocalDateString(new Date(date))}`}>
        <TooltipItem
          noTruncate={true}
          metric="Average Quantity Sold"
          value={formatNumber(quantitySold, "rateFloat","", "d", 2)}/>
        <TooltipItem
          noTruncate={true}
          metric="Percent Change (Regression)"
          value={formatNumber(metaData.slope*100, "percentFloat")}/>
      </BaseTooltip>
    )
  } else {
    return null
  }
}

export const DemandSpark = ({ cell }: { cell: any; }) => {
  let slope = cell.row.original.demandMetaData.slope;
  let startingQuantity = cell.row.original.demandMetaData.startingQuantity 
  let endingQuantity = cell.row.original.demandMetaData.endingQuantity 
  let data = cell.row.original.demandSparkChart
  // let directionColor = Math.sign(slope) === -1 ? "#cc2525" : "#0b8036"
  

  const direction = (slope:number) => {
    let percentSlope = slope*100;

    let variance = .5

    if (percentSlope <= variance && percentSlope >= variance*-1) {
      return "gray"
      // return "white"
    } else if (percentSlope > .05) {
      return "#0b8036"
      // return "lime"
    } else {
      return "#cc2525"
      // return "pink"
    }
  }

  let directionColor = direction(slope)

  return (
    <div style={{ 
      width: "100%", 
      }} className="gap-1 h-full grid grid-cols-6 items-center ">
        <RatePoint quantity={startingQuantity} />
      <div className="col-span-3">
        <ResponsiveContainer
          height={35}
          >
          <ComposedChart 
            data={data}
            // className="bg-gray-200 rounded-md"
            >
            <Area
              type="bumpX"
              dataKey="quantitySold"
              // stroke={"black"}
              stroke="transparent"
              // stroke={directionColor}
              // fill={directionColor}
              fill="#8d8497"
              // fill="#f3f4f6"
              strokeWidth={1}
              activeDot={{ strokeWidth: 2, r: 2, stroke: "black" }}
              // dot={false}
            />
            <Tooltip 
              active={true}
              wrapperStyle={{ zIndex: 50 }}
              content={<CustomizedTooltip metaData={cell.row.original.demandMetaData}/>}
            />
            <Line
              type="linear"
              dataKey="regression"
              // stroke={"white"}
              // stroke={"black"}
              stroke={directionColor}
              strokeWidth={2}
              // strokeDasharray={"5 3"}
              dot={false}
              activeDot={false}
              // dot={{ strokeWidth: 1.5, r: 2, strokeDasharray: "" }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <RatePoint quantity={endingQuantity} />
      <Slope slope={slope} directionColor={directionColor} />
    </div>
  );
};

const Slope = ({ slope, directionColor }: { slope: number, directionColor:string }) => {
  return (
    <p
      // style={{ color: `${Math.sign(slope) === -1 ? "#b91c1c" : "#15803d"}` }}
      style={{ color: directionColor }}
      className="font-mono rounded-md bg-gray-100 text-xs h-full w-full flex items-center justify-center"
    >
      {formatNumber(slope*100, "percentFloat")}
    </p>
  );
};

const RatePoint = ({ quantity }: { quantity: number }) => {

  if (quantity < 3 && quantity > -3) {
    return (
      <p className="font-mono text-2xs h-full w-full flex items-center justify-center">
        {formatNumber(quantity, "rateFloat","", "d", 2)}
      </p>
    );
    
  } else {
    return (
      <p className="font-mono text-2xs h-full w-full flex items-center justify-center">
        {formatNumber(quantity, "rateFloat","", "d")}
      </p>
    );
  }
};
