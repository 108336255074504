import React, { useContext } from "react";
import { AsyncStatus, IObject, IOption, OrganizeByOptions } from "types";
import { Header, Column, flexRender } from "@tanstack/react-table";
import { RadioDropdown, MultiselectDropdown } from "../../components/Table/dropdown";
import { DEMAND_METRICS, ORGANIZE_BY_TOP_PERFORMERS, SORT_OPTIONS } from "utils/constants";
import * as t from 'utils/table-styles'
import { formatNumber, saveState, styleList } from "utils/helper-ts";
import { DemandContext, TopPerformersContext } from "context";

let headerBorders = "border-r border-b border-border-internal";
let baseHeaderWrapper = `${headerBorders} relative w-full h-full px-3 py-[.5em] font-mono text-xs capitalize`;
let baseInner = `px-1 py-1 `;

export const DefaultHeaderCellMap = ({
  header,
  render,
  currency,
  loading,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  currency: string;
  loading: AsyncStatus;
}) => {
  let headerName = header.column.columnDef.header;
  let typeBypass: any = header.column.columnDef;
  let accessorKey = typeBypass.accessorKey; // idk why this doesn't exist on the type

  if (loading === AsyncStatus.Loading) {
    return <DefaultLoadingHeader header={header} render={render} currency={currency} />;
  }

  switch (
    true // regex for flexible columns
  ) {
    case /metric/.test(accessorKey):
      return <MetricHeader header={header} render={render} width={170} />;
    case /rank/.test(accessorKey):
      return (
        <HeaderCellWrapper header={header} width={100}>
          <DefaultHeaderInner header={header} render={render} />
        </HeaderCellWrapper>
      );
    case /plot/.test(accessorKey):
      return (
        <HeaderCellWrapper header={header} width={100}>
          <DefaultHeaderInner header={header} render={render} />
        </HeaderCellWrapper>
      );
    case /productTitle/.test(accessorKey): // these may not make sense as defaults
    case /vendor/.test(accessorKey): // these may not make sense as defaults
      return <DropdownHeader header={header} render={render} width={300} />;
    case /slope/.test(accessorKey):
      return (
        <HeaderCellWrapper header={header} width={400}>
          <DefaultHeaderInner header={header} render={render} />
        </HeaderCellWrapper>
      );
    default:
      return <HeaderCellSwitch header={header} render={render} />;
  }
};

export const HeaderCellSwitch = ({
  header,
  render,
  styles,
  scrollTo,
  width,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  styles?: string;
  scrollTo?: React.RefObject<HTMLDivElement>;
  width?: number;
}) => {
  if (header.column.getCanSort()) {
    return (
      <SortableHeaderCell
        header={header}
        render={render}
        scrollTo={scrollTo ? scrollTo : undefined}
        styles={styles}
      />
    );
  } else if (header.column.id === "select") {
    return (
      <th className={t.headerBorders} style={{ width: 40 }}>
        <div className={`flex items-center rounded-md`}>
          <DefaultHeaderInner header={header} render={render} />
        </div>
        <Resizer header={header} />
      </th>
    );
  } else {
    return (
      <th className={t.baseHeaderWrapper + " " + styles} style={{ width: width || header.getSize() }}>
        <div
          className={`relative flex justify-between items-center px-1 rounded-md  transition-all overflow-visible text-base-text`}
        >
          <DefaultHeaderInner header={header} render={render} />
        </div>
        <Resizer header={header} />
      </th>
    );
  }
};

export const SortableHeaderCell = ({
  header,
  render,
  scrollTo,
  styles,
  width,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  scrollTo?: React.RefObject<HTMLDivElement>;
  styles?: string;
  width?: number;
}) => {
  function sortToggler(item: IObject) {
    switch (item.key) {
      case "asc":
        return header.column.toggleSorting(false);
      case "desc":
        return header.column.toggleSorting(true);
      default:
        return header.column.clearSorting();
    }
  }

  return (
    <th className={t.baseHeaderWrapper + " " + styles} style={{ width: width || header.getSize() }}>
      <div
        className={`relative flex justify-between items-center px-1 rounded-md  transition-all overflow-visible text-base-text`}
        ref={scrollTo}
      >
        <RadioDropdown
          type="sort"
          options={SORT_OPTIONS}
          option={{
            key: header.column.getIsSorted() || "none",
            value: header.column.getIsSorted() || "None",
          }}
          onSelect={sortToggler}
        >
          <DefaultHeaderInner header={header} render={render} />
        </RadioDropdown>
      </div>
      <Resizer header={header} />
    </th>
  );
};

export const MetricHeader = ({
  header,
  render,
  width,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  width: number;
}) => {
  const { metrics, updateMetrics } = useContext(DemandContext);

  function handleMetricSelection(item: any) {
    updateMetrics(item);
  }

  // header.depth is a new pattern introduced here, not terrible, but not specific to function
  if (header.depth === 2) {
    return (
      <th
        className={t.baseHeaderWrapper + " " + "bg-highlight-gentle"}
        style={{ width: width || header.getSize() }}
      >
        <div
          className={`relative flex justify-center items-center px-1 rounded-md  transition-all overflow-visible`}
        >
          <MultiselectDropdown
            options={DEMAND_METRICS}
            onSelect={handleMetricSelection}
            selected={metrics}
          />
        </div>
      </th>
    );
  } else {
    return (
      <th className={t.baseHeaderWrapper} style={{ width: width || header.getSize() }}>
        <div
          className={`relative flex justify-start items-center px-1 rounded-md  transition-all overflow-visible`}
        >
          <div className="px-1.5 py-1 rounded-md ">
            <p className={t.baseInner}>Metric</p>
          </div>
        </div>
      </th>
    );
  }
};

export const HeaderCellWrapper = ({
  children,
  header,
  width,
  styles,
}: {
  header: Header<Column<any>, unknown>;
  children: React.ReactNode;
  width?: number;
  styles?: string;
}) => {
  let wrapperStyle = styleList([
    t.baseHeaderWrapper,
    styles || "",
    header.depth === 2 ? "bg-highlight-gentle" : "",
  ]);

  return (
    <th className={wrapperStyle} style={{ width: width || header.getSize() }}>
      <div className={`flex items-center px-1 rounded-md`}>{children}</div>
      <Resizer header={header} />
    </th>
  );
};

export const StickyHeaderCellWrapper = ({
  children,
  header,
}: {
  header: Header<Column<any>, unknown>;
  children: React.ReactNode;
}) => {
  return (
    <th
      className={t.baseHeaderWrapper + " " + "sticky left-0 bg-base-gentle z-10"}
      style={{ width: header.getSize() }}
    >
      <div className={`flex items-center px-1 rounded-md truncate`}>{children}</div>
    </th>
  );
};
export const TotalsHeaderCellWrapper = ({
  children,
  header,
  styles,
}: {
  header: Header<Column<any>, unknown>;
  children: React.ReactNode;
  styles?: string;
}) => {
  let wrapperStyle = styleList([
    t.baseHeaderWrapper,
    styles || "",
    "bg-highlight-gentle",
    "font-mono-bold",
  ]);
  return (
    <th className={wrapperStyle} style={{ width: header.getSize() }}>
      <div className={`flex justify-center items-center px-1 rounded-md truncate`}>{children}</div>
    </th>
  );
};

export const DefaultHeaderInner = ({
  header,
  render,
  styles,
  forcedValue,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  styles?: string;
  forcedValue?: string;
}) => {
  let wrapperStyle = styleList(["px-1.5 py-1 rounded-md", styles || ""]);

  return (
    <div className={wrapperStyle}>
      <p className={t.baseInner}>{forcedValue ? forcedValue : render}</p>
    </div>
  );
};

export const CurrencyHeaderInner = ({
  header,
  render,
  currency,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  currency: string;
}) => {
  // because backend is wrong, should always be string, or better yet always number
  if (typeof header.column.columnDef.header === "string") {
    let value = parseInt(header.column.columnDef.header);
    return (
      <div className="px-1.5 py-1 rounded-md ">
        <p className={t.baseInner}>{formatNumber(value, "currency", currency)}</p>
      </div>
    );
  } else if (typeof header.column.columnDef.header === "number") {
    return (
      <div className="px-1.5 py-1 rounded-md ">
        <p className={t.baseInner}>
          {formatNumber(header.column.columnDef.header, "currency", currency)}
        </p>
      </div>
    );
  } else {
    return null;
  }
};

export const Resizer = ({ header }: { header: Header<Column<any>, unknown> }) => {
  return (
    <div
      {...{
        onMouseDown: header.getResizeHandler(),
        onTouchStart: header.getResizeHandler(),
        className: `resizer ${header.column.getIsResizing() ? "isResizing" : ""}`,
      }}
    >
      <div className="resize-handle" />
    </div>
  );
};

export const DefaultLoadingHeader = ({
  header,
  render,
  currency,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  currency: string;
}) => {
  return (
    <HeaderCellWrapper header={header} styles={"border-b border-r border-gray-200"}>
      <DefaultHeaderInner header={header} render={render} styles="text-gray-300" />
    </HeaderCellWrapper>
  );
};
/* 
rule of three, its the third duplicate code, 
normally I would generalize this but atm we have nasty planning pr 
and same dropdown used there so postponing refactor
*/
export const DropdownHeader = ({
  header,
  render,
  scrollTo,
  styles,
  width,
}: {
  header: Header<Column<any>, unknown>;
  render: React.ReactNode;
  width?: number;
  scrollTo?: React.RefObject<HTMLDivElement>;
  styles?: string;
}) => {
  const { organizeBy, updateOrganizeBy } = useContext(TopPerformersContext);

  return (
    <th className={t.baseHeaderWrapper + " " + styles} style={{ width: width || header.getSize() }}>
      <div
        className={`relative flex justify-between items-center px-1 rounded-md  transition-all overflow-visible text-base-text`}
        ref={scrollTo}
      >
        <RadioDropdown
          type="dropdown"
          options={ORGANIZE_BY_TOP_PERFORMERS}
          option={organizeBy}
          onSelect={({ key, value }: IOption) => {
            updateOrganizeBy({ key, value } as OrganizeByOptions);
            saveState("top_perf_page_org", { key, value });
          }}
        >
          <DefaultHeaderInner header={header} render={render} />
        </RadioDropdown>
      </div>
    </th>
  );
};
