export const columns = [
  {
    accessor: "product",
    Header: "Product"
  },
  {
    accessor: "variant",
    Header: "Variant",
    width: 200
  },
  { accessor: "image", Header: "Image", width: 70},
  { accessor: "description", Header: "Description"},
  { accessor: "supplier", Header: "Vendor" },
  { accessor: "price", Header: "Price" },
  {
    accessor: "cost",
    Header: "Cost"
  },
  { accessor: "inventoryStatus", Header: "Inventory Status" },
];
