import { PAGE_COUNT } from "utils/constants";
import React, { useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { PillCallout } from "components/Pill";

export const Pagination = ({ 
  table, 
  pagination,
  totalRowCount
}:{ 
  table: any; 
  pagination: PaginationState;
  totalRowCount?: number;
}) => {
  const [newPageIndex, setNewPageIndex] = useState(table.getState().pagination.pageIndex);

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0;
    setNewPageIndex(page);
  };
  const handleButtonClick = () => {
    table.setPageIndex(newPageIndex);
  };

  let buttonStyle = "bg-darken-1 hover:cursor-pointer hover:bg-darken-2 h-5 flex items-center py-1 px-2 disabled:bg-darken-1 disabled:opacity-50 disabled:cursor-not-allowed bg-base-gentle"

  return (
    <div className="flex w-full h-[35px] px-3 gap-3 items-center border-t border-solid border-border-external text-xs text-base-navtext sticky left-0 bottom-0 bg-base-gentle shadow-modal">
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            table.setPageIndex(0);
          }}
          disabled={!table.getCanPreviousPage()}
          className={`${buttonStyle} rounded border border-border-external`}
        >
          First
        </button>
        <div className="inline-flex border border-border-external rounded">
          <button
            onClick={() => {
              table.previousPage();
            }}
            disabled={!table.getCanPreviousPage()}
            className={`${buttonStyle} rounded-l border border-r-border-external`}
            >
            Prev
          </button>
          <button
            onClick={() => {
              table.nextPage();
            }}
            disabled={!table.getCanNextPage()}
            className={`${buttonStyle} rounded-r`}
            >
            Next
          </button>
        </div>
        <button
          onClick={() => {
            table.setPageIndex(table.getPageCount() - 1);
          }}
          disabled={!table.getCanNextPage()}
          className={`${buttonStyle} rounded border border-border-external`}
        >
          Last
        </button>
      </div>
      <div className="flex items-center gap-1">
        <input
          type="number"
          defaultValue={table.getState().pagination.pageIndex + 1}
          onChange={handlePageInputChange}
          className="border p-1 rounded w-16 h-5 border border-border-external text-xs"
        />
        <button
          disabled={!table.getPageOptions().includes(newPageIndex)}
          onClick={handleButtonClick}
          className="bg-darken-1 hover:cursor-pointer hover:bg-darken-2 py-1 h-5 px-2 rounded border border-border-external flex justify-center items-center disabled:bg-darken-1 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Go to Page {newPageIndex + 1}
        </button>
      </div>
      {table.getPageCount() > 1 ?
        <PaginationInfo>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()} Pages
        </PaginationInfo>
      :null
      }
      {
        totalRowCount?
        <PaginationInfo>
          {totalRowCount} Total Items
        </PaginationInfo>
        :
        null
      }
    </div>
  );
};

const PaginationInfo = ({
  children
}: {
  children?: React.ReactNode
}) => {
  return  <p className="flex items-center justify-center bg-base-text text-base-inverted px-3 py-1 rounded h-5">
  {children}
</p>
}

export default Pagination;
