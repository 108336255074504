import React from 'react';

export const ChartBackground = ({
  width,
  height,
  embedded,
  children
}: {
  width?: string | number;
  height?: string | number;
  embedded?: boolean;
  children: React.ReactNode;
}) => {
  let styles = embedded ?
    `p-4 bg-white border-b border-solid border-border-internal`
    :
    `bg-base-lightwhite p-4 rounded-[10px] shadow-dashboard border border-solid border-border-internal`;
return (
  <div 
    style={{ width: width || "100%", height: height || 300 }} 
    className={styles}
    >
    {children}
  </div>
  )
}