import Modal from "./BasicModal/index";
import Button from "components/Button";
import { openInNewTab } from "utils/helper-ts";

interface ModalInterface {
  close: () => void;
  visible: boolean;
  sectionTitle: string;
}

export const NoRecommendationsModal = ({ sectionTitle, close, visible }: ModalInterface) => {
  return (
    <Modal title={sectionTitle} visible={visible} imageClassName={"mx-auto"} showBackground={true} onCancel={close}>
      <div className="prose max-w-prose text-base-text p-8">
        <div className="w-[1/2]">
          <span className="text-black text-base font-normal">
            {"No recommendations found for the current selection. Please broaden your selection or disable the 'Recommended Purchases' filter."}
          </span>
         
        </div>
      </div>
    </Modal>
  );
};
