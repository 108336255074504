import React from "react";
import { ReactComponent as Triangle } from "assets/images/triangle_solid.svg";
import { VerticalDirection } from "types";
import { decimalToPercent, formatPercent, decimalShort } from "utils/helper-ts";

const pillWrapperStyle =
  "font-mono-medium rounded-md px-2 h-[1.6em] flex justify-center items-center text-lg";
const pillTextStyle = "flex items-center text-xs";

// up down string is ugly code
export const ChangePill = ({
  change, // float
  decimal,
}: {
  change: number;
  decimal?: boolean;
}) => {
  function getDirection(number: number): VerticalDirection {
    let numDirection = Math.sign(number);

    if (numDirection === 1) {
      return "up";
    } else if (numDirection === -1) {
      return "down";
    } else {
      return "none";
    }
  }

  let type = getDirection(change);

  return (
    <div
      className={`${type === "up" ? "bg-valence-positive" : "bg-valence-negative-lightest"} 
    ${pillWrapperStyle}`}
    >
      <p
        className={`${
          type === "up" ? "text-valence-positive-darken" : "text-valence-negative-darken"
        } ${pillTextStyle}`}
      >
        <UpAndDownIndicator direction={type} />
        {decimal ? <DecimalInner change={change} /> : <PercentInner change={change} />}
      </p>
    </div>
  );
};

const PercentInner = ({ change }: { change: number }) => {
  let num = isFinite(change) && !!change ? formatPercent(change) : "-";
  return <>{num}</>;
};

const DecimalInner = ({ change }: { change: number }) => {
  let num = isFinite(change) && !!change ? decimalShort(change) : "-";
  return <>{num}</>;
};

export const ActionPill = ({ text }: { text: string }) => {
  return (
    <div className={`bg-red-100 ${pillWrapperStyle}`}>
      <p className={`text-red-900 ${pillTextStyle}`}>{/* {text} */} Take Action</p>
    </div>
  );
};

const UpAndDownIndicator = ({ direction }: { direction: VerticalDirection }) => {
  if (direction === "up") {
    return <Triangle width={8} />;
  } else if (direction === "down") {
    return <Triangle width={8} className="rotate-180" />;
  } else {
    return <Triangle width={0} />;
  }
};

export const PillCallout = ({
  inline,
  children,
  position,
  color
}: {
  inline?: boolean;
  position?: "top-right"
  children: React.ReactNode;
  color?: "highlight" | "dark" //  | "light"
}) => {
  let colors = "bg-green-300 text-base-text"
  if (color === "dark")
    colors = "bg-base-text text-base-inverted"

  if (position === "top-right") {
    return (
      <div className={`absolute -top-2 -right-2 px-2 rounded-full text-2xs font-mono capitalize z-50 ${colors}`}>
        {children}
      </div>
    );
  } else {
    if (inline) {
      return (
        <div className={`m-2 px-2 rounded-full text-2xs font-mono capitalize w-fit z-50 ${colors}`}>
          {children}
        </div>
      );
    } else {
      return (
        <div className={`absolute -bottom-2 -right-6 px-2 rounded-full text-2xs font-mono capitalize z-50 ${colors}`}>
          {children}
        </div>
      );
    }

  }
};

export const SimplePillCallout = ({
  inline,
  children,
  position
}: {
  inline?: boolean;
  position?: "top-right"
  children: React.ReactNode;
}) => {
  return (
    <div className="w-fit absolute text-nowrap -bottom-3 px-2 rounded-full bg-gray-200 text-[8px] text-base-text z-50 border-black">
      {children}
    </div>
  );
};
