import DateRangeDropdown from "components/DropdownDateRange";
import Modal from "./BasicModal/index";
import {
  DATE_FREQUENCY_TIME_SERIES,
  DATE_OPTIONS,
  DATE_OPTIONS_FORECAST,
  FORECAST_OVERRIDE_BASE_DATA_OPTIONS,
  FORECAST_OVERRIDE_OPTIONS,
  FORECAST_OVERRIDE_REFERENCE_OPTIONS,
  FORECAST_OVERRIDE_SELECTION_OPTIONS,
  PLANNING_DATE_OPTIONS,
  TIME_SERIES_OPTIONS,
} from "utils/constants";
import { AsyncStatus, IDateOption, IFilterPlus, IOption } from "types";
import React, { useContext, useEffect, useState } from "react";
import AreaTopBar from "components/Dashboard/AreaTopBar";
import { ChartBackground } from "components/ChartWrapper";
import DemandTimeseries from "components/DemandTimeseries";
import { WidgetRow } from "components/Organization/Row";
import { ModalRow } from "components/Organization/Row";
import SystemForecast from "./SystemForecast";
import ForecastOverrides from "./ForecastOverrides";
import OverrideReference from "./OverrideReference";
import { ModalContext } from "context";
import client from "api";
import { formatFilter } from "utils/helper-ts";
import Dropdown from "components/Dashboard/Dropdown";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormValues } from "pages/alerts/AddAlerts";
import { LoadingWrapper } from "components/Loading";
import { subYears, addDays } from "date-fns";
import CurrentForecast from "./CurrentForecast";
import { IVariantDetail } from "types/ModalTypes";
import BreadCrumb from "components/Breadcrumb";

export interface ISystemForecast {
  sum: number;
  average: number;
  days: number;
}

export interface IManualForecast {
  sum: number;
  average: number;
}

export const ForecastOverrideModal = ({
  close,
  visible,
  product,
  variant,
  variantTitle,
  setEdited,
}: {
  close: () => void;
  visible: boolean;
  product: string;
  variant: string;
  variantTitle: string;
  setEdited: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { OpenModal, forecastOverrideDateRange, updateForecastOverrideDateRange } =
    useContext(ModalContext);
  const [overrideUnit, setOverrideUnit] = useState(FORECAST_OVERRIDE_OPTIONS[2]);
  const [updateChart, setUpdateChart] = useState(false);
  const [loadingSave, setLoadingSave] = useState<AsyncStatus>(AsyncStatus.Loaded);
  const [loadingRevert, setLoadingRevert] = useState<AsyncStatus>(AsyncStatus.Loaded);
  const [originalValue, setOriginalValue] = useState("");
  const [baseData, setBaseData] = useState<IOption>(FORECAST_OVERRIDE_BASE_DATA_OPTIONS[0]);
  const [modifierType, setModifierType] = useState<IOption>(FORECAST_OVERRIDE_REFERENCE_OPTIONS[0]);
  const [variantReference, setVariantReference] = useState<IOption>(
    FORECAST_OVERRIDE_SELECTION_OPTIONS[0]
  );
  const [referenceTimeFrame, setReferenceTimeFrame] = useState(forecastOverrideDateRange);
  const [showApply, setShowApply] = useState(false);
  const [hasManualForecast, setHasManualForecast] = useState(false);
  const [systemForecast, setSystemForecast] = useState<ISystemForecast>({
    sum: 0,
    average: 0,
    days: 0,
  });
  const [currentForecast, setCurrentForecast] = useState<IManualForecast>({ sum: 0, average: 0 });

  const formValues = {
    change: originalValue,
  };
  const [overrideRefFilter, updateOverrideRefFilter] = useState<IFilterPlus[]>([
    { key: "more", value: "Filter & Search", name: "Filter & Search" },
  ]);
  const [variantData, setVariantData] = useState<IVariantDetail>({
    productTitle: "",
    category: "",
    pid: "",
    vid: "",
    variantTitle: "",
    sku: "",
    price: "",
    cost: "",
    marginRange: 0,
    tags: [],
    image: "",
    vendor: "",
    totalUnitsOnHand: "",
    inventoryStatus: "",
    averageLeadTime: "",
    sellThroughRate: "",
  });
  const [loadingVariantInfo, setLoadingVariantInfo] = useState<AsyncStatus>(AsyncStatus.Loading);

  async function getForecast() {
    try {
      const res = await client.get(`/get-forecasts`, {
        params: {
          productId: product,
          variantId: variant,
          startDate: forecastOverrideDateRange.startDate,
          endDate: forecastOverrideDateRange.endDate,
        },
      });
      setSystemForecast(res.data.data.system);
      setCurrentForecast(res.data.data.current);
      setHasManualForecast(res.data.data.hasManualForecast);
    } catch (err: any) {
      console.log(err);
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<any>({
    defaultValues: formValues,
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ change }: any) => {
    if (loadingSave === AsyncStatus.Loading) {
      return;
    }
    setLoadingSave(AsyncStatus.Loading);
    if (overrideUnit.key === "percentage") {
      await client.post(
        "/edit-variant-forecast-percent",
        {
          productVariantData: [
            {
              productId: product,
              variantId: variant,
              percent: parseFloat(change) / 100,
            },
          ],
          startDate: forecastOverrideDateRange.startDate,
          endDate: forecastOverrideDateRange.endDate,
          startDateForHist: referenceTimeFrame.startDate,
          endDateForHist: referenceTimeFrame.endDate,
          selectData: variantReference.key === "other" ? "other" : baseData.key,
          filter: formatFilter(overrideRefFilter),
          aggregationType: modifierType.key,
        },
        {
          timeout: 60000,
        }
      );
    } else {
      await client.post(
        "/edit-variant-forecast-direct",
        {
          productVariantIds: [{ productId: product, variantId: variant }],
          isTotal: overrideUnit.key === "totalQuantity",
          yhat: parseFloat(change),
          startDate: forecastOverrideDateRange.startDate,
          endDate: forecastOverrideDateRange.endDate,
        },
        {
          timeout: 60000,
        }
      );
    }
    setEdited(true);
    getForecast();
    setUpdateChart((prev) => !prev);
    setLoadingSave(AsyncStatus.Loaded);
    setOriginalValue(change);
  };

  useEffect(() => {
    if (visible) {
      getForecast();
    }
  }, [visible]);

  useEffect(() => {
    if (originalValue !== watch("change")) {
      return;
    }
    setShowApply(false);

    let newValue = "";
    if (overrideUnit.key === "totalQuantity") {
      newValue = currentForecast.sum.toFixed(2).toString();
    } else if (overrideUnit.key === "quantityPerDay") {
      newValue = currentForecast.average.toFixed(2).toString();
    } else {
      return;
    }

    setOriginalValue(newValue);
    setValue("change", newValue);
  }, [overrideUnit, currentForecast]);

  useEffect(() => {
    if (baseData.key === "ownHistSales") {
      const startDate = subYears(forecastOverrideDateRange.startDate, 1);
      setReferenceTimeFrame({
        ...forecastOverrideDateRange,
        startDate: startDate,
        endDate: addDays(startDate, systemForecast.days),
      });
    } else {
      setReferenceTimeFrame(forecastOverrideDateRange);
      setVariantReference(FORECAST_OVERRIDE_SELECTION_OPTIONS[0]);
    }
    setShowApply(true);
  }, [baseData]);

  useEffect(() => {
    setShowApply(originalValue !== watch("change"));
  }, [originalValue, watch("change")]);

  useEffect(() => {
    if (overrideUnit.key === "percentage") {
      setOriginalValue("0");
      setValue("change", "0");
      setShowApply(false);
    }
  }, [overrideUnit]);
  useEffect(() => {
    if (!hasManualForecast && baseData.key === "manualForecast") {
      setBaseData(FORECAST_OVERRIDE_BASE_DATA_OPTIONS[0]);
    }
  }, [hasManualForecast]);

  const handleRevert = async () => {
    if (loadingRevert === AsyncStatus.Loading) {
      return;
    }

    setLoadingRevert(AsyncStatus.Loading);
    const payload = {
      productVariantData: [{ productId: product, variantId: variant }],
      startDate: forecastOverrideDateRange.startDate,
      endDate: forecastOverrideDateRange.endDate,
    };

    await client.delete("/delete-manual-variant-forecast", {
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    });
    setUpdateChart((prev) => !prev);
    setLoadingRevert(AsyncStatus.Loaded);
    getForecast();
    setEdited(true);
  };

  const getVariantModalInfo = async (variantId: string) => {
    try {
      const res = await client.get("/get-variant-modal-info", { params: { variantId } });
      if (res.data.data !== false) {
        setVariantData(res.data.data);
      }
      setLoadingVariantInfo(AsyncStatus.Loaded);
    } catch (err: any) {
      console.error(err);
    }
  };

  useEffect(() => {
    getVariantModalInfo(variant);
  }, [variant]);

  /* duplicate code on purpose I dont think we will need this in future once we have filter + thats why */
  const handleGoToVariant = () => {
    close();
    OpenModal("variant", undefined, undefined, undefined, variant);
  };
  const handleGoToProduct = () => {
    close();
    OpenModal("product", undefined, undefined, variantData.pid);
  };

  const handleGoToCategory = () => {
    if (variantData.category) {
      close();
      OpenModal("category", undefined, variantData.category);
    }
  };

  const handleClickBreadCrumb = (id: string) => {
    if (id === "product") {
      handleGoToProduct();
    } else if (id === "category") {
      handleGoToCategory();
    } else {
      handleGoToVariant();
    }
  };

  return (
    <Modal
      title={
        <div className="flex items-center justify-center gap-3">
          <div>
            <p>Forecast Override</p>
            <BreadCrumb
              data={[
                { name: variantData.category, type: "category" },
                { name: variantData.productTitle, type: "product" },
                { name: variantData.variantTitle, type: "variant" },
              ]}
              onClick={handleClickBreadCrumb}
              loading={loadingVariantInfo}
              // large
            />
          </div>
          <div className="ml-4">
            <DateRangeDropdown
              options={PLANNING_DATE_OPTIONS}
              option={forecastOverrideDateRange}
              onSelect={(selection: IDateOption) => {
                updateForecastOverrideDateRange(selection as IDateOption);
              }}
              allowPast={false}
              allowFuture={true}
              disabled
            />
          </div>
        </div>
      }
      visible={visible}
      onCancel={close}
      size={"full"}
      //If I remember correct this button design is not final so I just used figma export as placeholder
      rightIcon={
        <>
          {hasManualForecast && (
            <button
              className={`h-[38px] p-2.5  bg-[#F9C6C6] rounded-[10px] justify-start items-center gap-2.5 inline-flex ${loadingRevert === AsyncStatus.Loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              onClick={handleRevert}
            >
              <div className="text-[#6C0A0A] text-xs font-medium">Revert to System Forecast</div>
            </button>
          )}
          {showApply && (
            <button
              className={`h-[38px] p-2.5 bg-lime-200 rounded-[10px] justify-start items-center gap-2.5 inline-flex ${loadingSave === AsyncStatus.Loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              onClick={handleSubmit(onSubmit)}
            >
              <div className="text-lime-950 text-xs font-medium"> Apply Forecast Override</div>
            </button>
          )}
        </>
      }
    >
      <WidgetRow columns={6}>
        <div className="col-span-4">
        {
          systemForecast.sum === currentForecast.sum 
          ?
          <WidgetRow columns={1}>
            <SystemForecast data={systemForecast} />
          </WidgetRow>
          :
          <WidgetRow columns={2}>
            <CurrentForecast system={systemForecast} current={currentForecast} />
            <SystemForecast data={systemForecast} />
          </WidgetRow>
        }
        <ForecastModalDemandTimeseries
            update={updateChart}
            product={product}
            variant={variant}
          ></ForecastModalDemandTimeseries>
         
         
        </div>
        <div className="col-span-2">

          <OverrideReference
            baseData={baseData}
            setBaseData={setBaseData}
            modifierType={modifierType}
            setModifierType={setModifierType}
            referenceTimeFrame={referenceTimeFrame}
            setReferenceTimeFrame={setReferenceTimeFrame}
            overrideRefFilter={overrideRefFilter}
            updateOverrideRefFilter={updateOverrideRefFilter}
            variantReference={variantReference}
            setVariantReference={setVariantReference}
            disabled={overrideUnit.key !== "percentage"}
            hasManualForecast={hasManualForecast}
            fixedDays={systemForecast.days}
            setShowApply={setShowApply}
          >
            <ForecastOverrides
              overrideUnit={overrideUnit}
              setOverrideUnit={setOverrideUnit}
              control={control}
              setValue={setValue}
              watch={watch}
              originalValue={originalValue}
              errors={errors}
            />
          </OverrideReference>
        </div>
      </WidgetRow>
    </Modal>
  );
};

export const ForecastModalDemandTimeseries = ({
  update,
  product,
  variant,
}: {
  update: boolean;
  product: string;
  variant: string;
}) => {
  const {
    timeSeriesType,
    updateTimeSeriesType,
    timeSeriesFrequency,
    updateTimeSeriesFrequency,
    forecastOverrideDateRange,
  } = useContext(ModalContext);

  const [data, setData] = useState([]);
  const [timeSeriesTime, setTimeSeriesTime] = useState(forecastOverrideDateRange);

  async function getDemandData() {
    let query = {
      ...timeSeriesTime,
      frequency: timeSeriesFrequency.key,
      variantId: variant,
      showPreviousYear: true,
    };

    return await client.get(`/get-variant-modal-demand-chart`, { params: query });
  }

  useEffect(() => {
    async function getDemandDataTable() {
      try {
        const res = await getDemandData();
        setData(res.data.data);
      } catch (err: any) {
        console.log(err);
      }
    }
    getDemandDataTable();
  }, [timeSeriesTime, update]);

  return (
    <div className="p-4">
      <AreaTopBar areaName="Demand Comparison and Forecast">
        {/* <DateRangeDropdown
          options={PLANNING_DATE_OPTIONS}
          option={timeSeriesTime}
          onSelect={(selection: IDateOption) => {
            setTimeSeriesTime(selection as IDateOption);
          }}
          allowFuture
          disabled
        /> */}
        {/* <Dropdown
          options={DATE_FREQUENCY_TIME_SERIES}
          option={timeSeriesFrequency}
          onSelect={(item: IOption) => {
            updateTimeSeriesFrequency(item);
          }}
          disabled
        /> */}
        <Dropdown
          options={TIME_SERIES_OPTIONS}
          option={timeSeriesType}
          onSelect={(item: IOption) => {
            updateTimeSeriesType(item);
          }}
        />
      </AreaTopBar>
      <ChartBackground height={450}>
        <DemandTimeseries
          id="forecastModal"
          type="forecastModal"
          chartData={data}
          noBorder={true}
          loadingParent={AsyncStatus.Loaded}
          context={ModalContext}
          horizontalLegend
        />
      </ChartBackground>
    </div>
  );
};
export default ForecastOverrideModal;
