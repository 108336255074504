import React, { useContext, useEffect, useState } from "react";
import { Cell } from "@tanstack/react-table";
import { OrganizeBy } from "types";
import { ModalContext } from "context";
import { 
  formatNumber,
  styleList,
baseSpacing,
baseWrapper,
baseInner,
hoverable,
clickable,
tdBorderStyle,
rowGroupingBorder,
lastRowStyle,
 } from "utils/helper-ts";
import { CopyButton } from "components/Button";

// these need to continue to be divided correctly
// the logic is confusing, and causes bugs in the table when there are more than one of these

interface DetailsViewProps {
  cell: Cell<any, any>;
  title: string;
  id: string; // pid
  productTitle: string;
  spannedRows?: number;
  vid?: string;
  category?: string;
  products?: string;
  variants?: string;
  variantTitleOnly?: boolean; // this is for the case where product is a separate col
  organizeBy?: OrganizeBy;
  hideButton?: boolean;
  isColSpan?: boolean; // as in the case of demand where there isn't room for two columns for product and variant
  setProductId?: React.Dispatch<React.SetStateAction<string>>;
}

// these are local, I'm starting to hate tailwind
let styles = styleList([
  baseSpacing,
  // clickable,
  "flex justify-between items-center group gap-2",
]);
let innerBase = "rounded-md px-2 py-2 shrink truncate";

// this is while the modal selection is under construction
let innerStylesDisabled = "h-fit font-mono" + " " + "rounded-md px-2 py-2"; // modified inner base

let innerStyles =
  "underline innerStyles font-mono decoration-dotted decoration-base-text underline-offset-4 cursor-pointer border border-black border-opacity-0 hover:border-opacity-20 hover:bg-gray-100" +
  " " +
  innerBase;

export const DetailsCell = ({
  cell,
  title,
  id,
  vid,
  category,
  products,
  variants,
  organizeBy,
  productTitle,
  variantTitleOnly,
  hideButton = false,
  isColSpan,
  setProductId,
  spannedRows,
}: DetailsViewProps) => {
  title = title ? title : "No Title";

  switch (organizeBy) {
    case "variant":
      return (
        <VariantCell
          cell={cell}
          title={title}
          productTitle={productTitle}
          variantTitleOnly={variantTitleOnly}
          vid={vid}
          spannedRows={spannedRows}
        />
      );
    case "product":
      return <ProductCell cell={cell} title={title} id={id} />;
    case "category":
      return <CategoryCell cell={cell} title={title} />;
    case "collection": // not handled
    case "vendor": // not handled
      return <VendorCell cell={cell} title={title} />;
    case "location": // not handled
    case "shop": // not handled
    default:
      return <DefaultGroupingCell cell={cell} title={title} />; // unhandled organization
  }
};

const DefaultGroupingCell = ({ cell, title }: { cell: Cell<any, any>; title: string }) => {
  return (
    <div className={styles}>
      <p className={innerStylesDisabled}>{title}</p>
    </div>
  );
};

const VariantCell = ({
  cell,
  title,
  productTitle,
  variantTitleOnly,
  vid,
  spannedRows,
}: {
  cell: Cell<any, any>;
  title: string;
  productTitle: string;
  variantTitleOnly?: boolean;
  vid?: string;
  spannedRows?: number;
}) => {
  const { OpenModal } = useContext(ModalContext);

  if (variantTitleOnly) {
    return (
      <div
        className={styles}
      >
        <p 
          className={innerStyles}
          onClick={() => {
            OpenModal("variant", undefined, undefined, undefined, vid);
          }}
        >
          {title}
        </p>
        {/* <CopyButton copyText={title}/> */}
      </div>
    );
  } else {
    return (
      <div className={styleList([...styles, "flex-col"])}>
        <p
          onClick={() => {
            OpenModal("variant", undefined, undefined, undefined, vid);
          }}
          className={innerStyles + " font-mono-bold min-h-[20px]"}
        >
          Variant: {title}
        </p>
        {spannedRows && spannedRows <= 1 ? null : (
          <p className={innerStylesDisabled + " " + "truncate"}>Product: {productTitle}</p>
        )}
      </div>
    );
  }
};

const ProductCell = ({ cell, title, id }: { cell: Cell<any, any>; title: string; id: string }) => {
  const { OpenModal } = useContext(ModalContext);

  return (
    <div className={styles}>
      <p 
        className={innerStyles}
        onClick={() => {
          OpenModal("product", undefined, undefined, id);
        }}
      >
        {title}
        </p>
      {/* <CopyButton copyText={title}/> */}
    </div>
  );
};

const CategoryCell = ({ cell, title }: { cell: Cell<any, any>; title: string }) => {
  const { OpenModal } = useContext(ModalContext);
  return (
    <div className={styles}>
      <p 
      className={innerStyles}
      onClick={() => {
        OpenModal("category", undefined, title);
      }}
      >
        {title}
      </p>
    </div>
  );
};
const VendorCell = ({ cell, title }: { cell: Cell<any, any>; title: string }) => {
  const { OpenModal } = useContext(ModalContext);
  return (
    <div className={styles}>
      <p 
        className={innerStyles}
        onClick={() => {
          OpenModal(
            "vendor",
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            title
          );
        }}
        >{title}</p>
      {/* <CopyButton copyText={title}/> */}
    </div>
  );
};
