import React, { useContext, useEffect, useState } from "react";
import { IObject, IDays, IFilterPlus, AsyncStatus, IDateOption } from "types";
import { InventoryOrganizationOptions } from "pages/inventory/types";
import { SortingState } from "@tanstack/react-table";
import {
  DATE_OPTIONS_FORECAST,
  DATE_OPTIONS,
  PLACEHOLDER_ROWS_INVENTORY,
  INVENTORY_VARIANT_DEFAULT_SORT,
} from "utils/constants";
import { StatesContext } from "./States";
import { processAndSet, saveState } from "utils/helper-ts";

interface IInventoryContext {
  statesFetched: boolean;
  updateStatesFetched: React.Dispatch<React.SetStateAction<boolean>>;
  inventoryProduct: IObject;
  updateInventoryProduct: (product: IObject) => void;
  fInventorySKUs: string[];
  updateFInventorySKUs: (SKUs: string[]) => void;
  inventorySelectedVariants: string[];
  updateInventorySelectedVariants: (inventorySelectedVariants: string[]) => void;
  fetchInventoryVariants: boolean;
  updateFetchInventoryVariants: (fetchVariants: boolean) => void;
  inventoryDays: IDays;
  updateInventoryDays: (k: string, d: IObject) => void;
  collapseInventory: number;
  updateCollapseInventory: (collapse: number) => void;
  organizeByInventory: InventoryOrganizationOptions;
  updateOrganizeByInventory: (organizeBy: InventoryOrganizationOptions) => void;
  isInventoryInitialized: boolean;
  updateIsInventoryInitialized: (isInventoryInitialized: boolean) => void;

  rows: any;
  updateRows: (rows: any) => void;

  inventoryFilter: IFilterPlus[];
  updateInventoryFilter: (items: any) => void;
  fInventoryCategories: string[];
  updateFInventoryCategories: (products: string[]) => void;
  fInventoryProducts: string[];
  updateFInventoryProducts: (products: string[]) => void;
  fInventoryVariants: string[];
  updateFInventoryVariants: (products: string[]) => void;

  loading: AsyncStatus;
  updateLoading: (loading: AsyncStatus) => void;
  sorting: SortingState;
  updateSorting: React.Dispatch<React.SetStateAction<SortingState>>;

  dateRange: IDateOption;
  updateDateRange: React.Dispatch<React.SetStateAction<IDateOption>>;

  inventoryTimeSeriesTime: IDateOption;
  updateInventoryTimeSeriesTime: (KPITime: IDateOption) => void;

  showChart: boolean;
  updateShowChart: React.Dispatch<React.SetStateAction<boolean>>;

  selectedVariantId: string;
  updateVariantId: React.Dispatch<React.SetStateAction<string>>;
  selectedProductId: string;
  updateProductId: React.Dispatch<React.SetStateAction<string>>;
  selectedRowData: any;
  updateRowData: React.Dispatch<React.SetStateAction<any>>;

  editedCells: any;
  updateEditedCells: (editedCells: any) => void;
  revertCells: boolean;
  updateRevertCells: React.Dispatch<React.SetStateAction<boolean>>;

  refreshTable: boolean;
  updateRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
  refreshChart: boolean;
  updateRefreshChart: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InventoryContext = React.createContext<IInventoryContext>({
  statesFetched: false,
  updateStatesFetched: () => {},
  inventoryProduct: { key: "", value: "All Products" },
  updateInventoryProduct: () => {},
  fInventorySKUs: [],
  updateFInventorySKUs: () => {},
  inventorySelectedVariants: [],
  updateInventorySelectedVariants: () => {},
  fetchInventoryVariants: true,
  updateFetchInventoryVariants: () => {},
  inventoryDays: {
    over: "30",
    forecast: "7",
  },
  updateInventoryDays: () => {},
  collapseInventory: 1,
  updateCollapseInventory: () => {},
  organizeByInventory: { key: "variants", value: "Variant" },
  updateOrganizeByInventory: () => {},
  isInventoryInitialized: false,
  updateIsInventoryInitialized: () => {},

  rows: [],
  updateRows: () => {},

  inventoryFilter: [{ key: "more", value: "Filter & Search", name: "Filter & Search" }],
  updateInventoryFilter: () => {},
  fInventoryCategories: [],
  updateFInventoryCategories: () => {},
  fInventoryProducts: [],
  updateFInventoryProducts: () => {},
  fInventoryVariants: [],
  updateFInventoryVariants: () => {},

  loading: AsyncStatus.Loading,
  updateLoading: () => {},
  sorting: [],
  updateSorting: () => {},

  dateRange: DATE_OPTIONS[3],
  updateDateRange: () => {},

  inventoryTimeSeriesTime: DATE_OPTIONS_FORECAST[2],
  updateInventoryTimeSeriesTime: () => {},

  showChart: true,
  updateShowChart: () => {},

  selectedVariantId: "",
  updateVariantId: () => {},
  selectedProductId: "",
  updateProductId: () => {},
  selectedRowData: {},
  updateRowData: () => {},

  editedCells: [],
  updateEditedCells: () => {},
  revertCells: true,
  updateRevertCells: () => {},

  refreshTable: false,
  updateRefreshTable: () => {},
  refreshChart: false,
  updateRefreshChart: () => {},
});

interface InventoryContextProps {
  children: React.ReactNode;
}

export const InventoryProvider = (props: InventoryContextProps) => {
  const { rawState } = useContext(StatesContext);
  const [statesFetched, setStatesFetched] = useState(false);
  const [inventoryProduct, setInventoryProduct] = useState<IObject>({
    key: "",
    value: "All Products",
  });
  const [fInventorySKUs, setFInventorySKUs] = useState<string[]>([]);
  const [inventorySelectedVariants, setSelectedInventoryVariants] = useState<string[]>([]);
  const [fetchInventoryVariants, setFetchInventoryVariants] = useState(true);
  const [inventoryDays, setInventoryDays] = useState<IDays>({
    over: "30",
    forecast: "7",
  });
  const [collapseInventory, setCollapseInventory] = useState(1);
  const [organizeByInventory, setOrganizeByInventory] = useState<InventoryOrganizationOptions>({
    key: "variants",
    value: "Variant",
  });
  const [isInventoryInitialized, setIsInventoryInitialized] = useState(false);

  const handleSetInventoryDays = (key: string, value: IObject) => {
    setInventoryDays((old) => ({ ...old, [key]: value.key }));
  };

  const [rows, setRows] = useState(Array(16).fill({ ...PLACEHOLDER_ROWS_INVENTORY }));

  const [inventoryFilter, setInventoryFilter] = useState<IFilterPlus[]>([
    {
      key: "topPerformers",
      value: "Top Performers",
      name: "Top Performers",
    },
  ]);
  const [fInventoryCategories, setFInventoryCategories] = useState<string[]>([]);
  const [fInventoryProducts, setFInventoryProducts] = useState<string[]>([]);
  const [fInventoryVariants, setFInventoryVariants] = useState<string[]>([]);
  const [loading, setLoading] = useState(AsyncStatus.Loading);
  const [sorting, setSorting] = useState<SortingState>(INVENTORY_VARIANT_DEFAULT_SORT);
  //new

  const [dateRange, setDateRange] = useState(DATE_OPTIONS[3]);

  const [inventoryTimeSeriesTime, setInventoryTimeSeriesTime] = useState(DATE_OPTIONS_FORECAST[2]);
  const [showChart, setShowChart] = useState(false);

  const [selectedVariantId, setVariantId] = useState("");
  const [selectedProductId, setProductId] = useState("");
  const [selectedRowData, setRowData] = useState({})

  const [editedCells, setEditedCells] = useState([]);
  const [revertCells, setRevertCells] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [refreshChart, setRefreshChart] = useState(false);

  useEffect(() => {
    if (rawState) {
      const parsed = JSON.parse(rawState);
      if (parsed) {
        processAndSet(parsed, "inventory_filter", setInventoryFilter);
        processAndSet(parsed, "inventory_sorting", setSorting);
      }
      setStatesFetched(true);
    }
  }, [rawState]);

  useEffect(() => {
    if (statesFetched) {
      saveState("inventory_sorting", sorting);
    }
  }, [sorting, statesFetched]);

  useEffect(() => {
    if (statesFetched) {
      saveState("inventory_filter", inventoryFilter);
    }
  }, [JSON.stringify(inventoryFilter), statesFetched]);

  return (
    <InventoryContext.Provider
      value={{
        statesFetched,
        updateStatesFetched: setStatesFetched,
        inventoryProduct,
        updateInventoryProduct: setInventoryProduct,
        fInventorySKUs,
        updateFInventorySKUs: setFInventorySKUs,
        inventorySelectedVariants,
        updateInventorySelectedVariants: setSelectedInventoryVariants,
        fetchInventoryVariants,
        updateFetchInventoryVariants: setFetchInventoryVariants,
        inventoryDays,
        updateInventoryDays: handleSetInventoryDays,
        collapseInventory,
        updateCollapseInventory: setCollapseInventory,
        organizeByInventory,
        updateOrganizeByInventory: setOrganizeByInventory,
        isInventoryInitialized,
        updateIsInventoryInitialized: setIsInventoryInitialized,

        rows,
        updateRows: setRows,

        inventoryFilter,
        updateInventoryFilter: setInventoryFilter,
        fInventoryCategories,
        updateFInventoryCategories: setFInventoryCategories,
        fInventoryProducts,
        updateFInventoryProducts: setFInventoryProducts,
        fInventoryVariants,
        updateFInventoryVariants: setFInventoryVariants,

        loading,
        updateLoading: setLoading,
        sorting,
        updateSorting: setSorting,

        dateRange,
        updateDateRange: setDateRange,

        inventoryTimeSeriesTime,
        updateInventoryTimeSeriesTime: setInventoryTimeSeriesTime,
        showChart,
        updateShowChart: setShowChart,

        selectedVariantId,
        updateVariantId: setVariantId,
        selectedProductId,
        updateProductId: setProductId,
        selectedRowData,
        updateRowData: setRowData,

        editedCells,
        updateEditedCells: setEditedCells,
        revertCells,
        updateRevertCells: setRevertCells,
        refreshTable,
        updateRefreshTable: setRefreshTable,
        refreshChart,
        updateRefreshChart: setRefreshChart,
      }}
    >
      {props.children}
    </InventoryContext.Provider>
  );
};
