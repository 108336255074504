import client from "api";
import { useEffect, useState, useContext } from "react";
import Modal from "./BasicModal/index";
import Loading from "components/Loading";
import * as Case from "change-case";
import { ModalContext } from "context";

interface MetricTooltipProps {
  title: string;
}

export const MetricModalTitle = ({ title }: MetricTooltipProps) => {
  const { OpenModal } = useContext(ModalContext);
  const hiddenMetrics = [
    "Demand Comparison and Forecast",
    "Bottom Performers",
    "Percent Change Override Reference",
    "Forecast Overrides",
    "System Forecast Over Period",
    "Current Forecast",
    "System Forecast",
    "ABC (Revenue)"
  ];
  const [hideHelp, setHideHelp] = useState(hiddenMetrics.includes(title));
  return (
    <>
      <div
        className={`group flex relative ${hideHelp ? "" : "cursor-pointer"} `}
        onClick={() => {
          if (!hideHelp) {
            OpenModal("metric", title);
          }
        }}
      >
        <span className="py-1">
          <p
            className={`underline decoration-dotted decoration-base-text underline-offset-4  ${
              hideHelp ? "" : "group-hover:bg-highlight-gentle"
            } rounded-md px-1 py-1`}
          >
            {title}
          </p>
        </span>
        {!hideHelp && (
          <span
            className="group-hover:opacity-100 transition-opacity bg-purple-base px-1 text-xs text-gray-100 rounded-full absolute -right-2
                -translate-y-[120%] opacity-0 m-4 mx-auto"
          >
            ?
          </span>
        )}
      </div>
    </>
  );
};

interface MetricModalInterface {
  close: () => void;
  visible: boolean;
  sectionTitle: string;
  content?: string;
}

interface MetricSection {
  sectionTitle: string;
  metricDetail: MetricDetail[];
}

interface MetricDetail {
  title: string;
  explanation: string;
  calculation?: string;
}

export const MetricModal = ({ sectionTitle, close, visible, content }: MetricModalInterface) => {
  const [loading, setLoading] = useState(false);
  const [metricExplanations, setMetricExplanations] = useState([]);

  async function fetchMetricExplanations() {
    try {
      const formattedSectionTitle = Case.paramCase(sectionTitle.toLocaleLowerCase());
      setLoading(true);
      const res = await client.get(`/get-metric-explanation`, {
        params: {
          querySection: formattedSectionTitle,
        },
      });
      setMetricExplanations(res.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    if (!content) {
      if (visible) {
        fetchMetricExplanations();
      }
    }
  }, [visible, sectionTitle]);

  return (
    <Modal title={sectionTitle} visible={visible} imageClassName={"mx-auto"} onCancel={close}>
      {loading ? (
        <Loading />
      ) : (
        <div className="prose max-w-prose text-base-text">
          {metricExplanations && metricExplanations.length > 0 ? (
            (metricExplanations || []).map((data: MetricDetail, index: number) => {
              return (
                <div className="rounded-md mt-7 mb-10 text-sm mx-6" key={data.title + "_" + index}>
                  <p className="font-bold -mb-2">{data.title}</p>
                  <p className="-mb-1">{data.explanation}</p>
                  {data.calculation ? (
                    <pre>
                      <code>{data.calculation}</code>
                    </pre>
                  ) : null}
                </div>
              );
            })
          ) : (
            <p>Nothing added yet.</p>
          )}

          <hr></hr>
          <div className="mx-7 mb-7 -mt-3">
            <p className="text-sm">
              All time frames are calculated from Universal Coordinated Time (UTC). For example the{" "}
              <b>Last 24 hours</b> filter would not produce <b>Total sales</b> over the last 24
              hours from your local time zone. Instead it will be 24 hours back from UTC-0. Many
              businesses work across time zones, so this system provides consistency throughout our
              user base.
            </p>
            <p className="text-sm">
              These explainations are designed to help you understand your data and the analysis
              we&apos;ve provided. If you have additional questions please contact us.{" "}
            </p>
          </div>
        </div>
      )}
    </Modal>
  );
};
