import { Cell } from "@tanstack/react-table";
import { LoadingCell } from "components/Table";
import { 
  formatNumber,
  styleList,
baseSpacing,
baseWrapper,
baseInner,
hoverable,
clickable,
tdBorderStyle,
rowGroupingBorder,
lastRowStyle,
 } from "utils/helper-ts";
import { 
  DefaultCell, 
  DetailsCell, 
  CellWrapper, 
  UnitsOnHandCell,
  FlagStringCell,
  FlagValueRange,
  CostCell,
  Pulse
 } from "components/Table";
import { AsyncStatus } from "types";

export const DefaultCellMap = ({
  cell,
  loading,
  spannedRows,
  currency,
}: {
  cell: Cell<any, any>;
  currency: string;
  spannedRows?: number;
  loading: AsyncStatus;
}) => {
  const { grouping, organizeBy, productId, productTitle, variantIdCount, variantId, metric } =
    cell.row.original;
  const accessorKey = (cell.column.columnDef as { accessorKey: string }).accessorKey;

  if (loading === AsyncStatus.Loading) {
    return <LoadingCell cell={cell} />;
  }

  switch (accessorKey) {
    case "productTitle":
    case "variantTitle":
    case "vendor":
      return (
        <CellWrapper cell={cell} styles={tdBorderStyle}>
          <DetailsCell
            cell={cell}
            title={cell.getValue()}
            id={productId}
            vid={variantId}
            organizeBy={organizeBy}
            productTitle={productTitle}
            variantTitleOnly={true}
          />
        </CellWrapper>
      );
    case "grouping":
      return (
        <CellWrapper cell={cell} styles={tdBorderStyle}>
          <DetailsCell
            cell={cell}
            title={grouping}
            id={productId}
            vid={variantId}
            organizeBy={organizeBy}
            variants={variantIdCount}
            productTitle={productTitle}
            spannedRows={spannedRows}
          />
        </CellWrapper>
      );
      case "unitsOnHand":
      case "onHandInventoryQuantity":
        return <UnitsOnHandCell cell={cell} />;
      case "cost":
      case "costOfItemsToPurchase":
      case "costPerItem":
        return <CostCell cell={cell} currency={currency}/>;
      case "turnOverRatio":
        return <FlagStringCell cell={cell}/>;
      case "coverageRate":
      case "dtoos":
        return <FlagValueRange cell={cell} minValue={0} maxValue={1}/>;
    default:
      return <DefaultCell cell={cell} />;
  }
};
