import client from "api";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PrimaryAccountButton, SecondaryAccountButton } from "components/Buttons";
import Modal from "components/Modal/BasicModal";
import loginImage from "../../assets/images/login_image.png";
import { PasswordFormInverted } from "pages/auth/password";
import { ModalContext } from "context";
import {
  AuthGlassWrapper,
  AuthContentWrapper,
  AuthFormHeader,
  AuthForm,
  AuthGlassWrapperFooter,
  AuthFormTextItem,
  AuthFormPasswordItem,
} from "layouts/AuthLayout";
import { AuthLink } from "components/Link/index"

const ResetPasswordWrapper = () => {
  const { OpenModal } = useContext(ModalContext);

  const [searchParams] = useSearchParams();
  const jwt = searchParams.get("jwt");

  useEffect(() => {
    if (jwt) {
      localStorage.setItem("token", jwt);
      localStorage.setItem("changePassword", "true");
    } else if (!localStorage.token && !jwt) {
      OpenModal("setPassword");
    }
  }, [jwt]);

  return (
    <>
      <AuthGlassWrapper>
        <AuthContentWrapper>
          <AuthFormHeader
            title="Create Password"
            subtitle="Bars will turn green when the password is strong enough."
          ></AuthFormHeader>
          <PasswordFormInverted />
        </AuthContentWrapper>
      </AuthGlassWrapper>
      <AuthGlassWrapperFooter text="Want to know more about what we can do for your business?">
        <AuthLink link="https://calendly.com/bucephalus/30min" text="Sign up for a demo" />
      </AuthGlassWrapperFooter>
    </>
  );
};

export const EmailPasswordResetForm = ({
  setVisible,
}: {
  setVisible: (boolean: boolean) => void;
}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const submitSetPasswordForm = async (event: React.FormEvent<HTMLFormElement>) => {
    // Preventing the page from reloading
    event.preventDefault();

    try {
      const res = await client.post(`/reset`, { email: email });

      if (res) {
        toast.success(`Sent password creation link to ${email}`, {
          autoClose: 99999999,
          hideProgressBar: true,
          closeOnClick: true,
        });

        navigate("/login");
        setVisible(false);
      }
    } catch (error: any) {
      toast.error(error.response.data);
    }
  };
  return (
    <>
      <label>EMAIL ADDRESS</label>
      <form onSubmit={submitSetPasswordForm} className="flex flex-col justify-center space-y-4">
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="Shopify Email"
          className="input rounded-lg"
        />
        <div className="flex flex-row justify-end py-3 gap-4">
          <a href="/login">
            <SecondaryAccountButton text="Return to Login" />
          </a>
          <PrimaryAccountButton type={"submit"} text={"Send Password Creation Link"} />
        </div>
        <p></p>
        <hr></hr>
        <p className="text-sm">
          If you have any problems setting up your account, please reach out through Live Chat in
          the bottom right corner of the screen.
        </p>
      </form>
    </>
  );
};

export default ResetPasswordWrapper;
