import React, { useContext, useEffect, useState } from "react";
import { AsyncStatus, IDateOption, IFilterPlus, IOption } from "types";
import { SortingState } from "@tanstack/react-table";
import { rewindDays } from "utils/date";
import {
  PLACEHOLDER_ROW_PLANNING,
  DATE_OPTIONS_FORECAST,
  DASHBOARD_TOP_CRITERIA_OPTIONS,
  DATE_OPTIONS,
  TIME_SERIES_OPTIONS,
  DATE_FREQUENCY_TIME_SERIES,
  PLANNING_DATE_OPTIONS,
} from "utils/constants";
import { processAndSet, processAndSetTime, processTime, saveState } from "utils/helper-ts";
import { StatesContext } from "./States";
interface IPlanningContext {
  statesFetched: boolean;
  updateStatesFetched: React.Dispatch<React.SetStateAction<boolean>>;
  sorting: SortingState;
  updateSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  planningFilter: IFilterPlus[];
  updatePlanningFilter: (items: any) => void;
  fPlanningCategories: string[];
  updateFPlanningCategories: (products: string[]) => void;
  fPlanningProducts: string[];
  updateFPlanningProducts: (products: string[]) => void;
  fPlanningSKUs: string[];
  updateFPlanningSKUs: (SKUs: string[]) => void;
  fPlanningVariants: string[];
  updateFPlanningVariants: (products: string[]) => void;
  vendor: string;
  setVendor: (value: string) => void;
  vendorOptions: any;
  setVendorOptions: (vendorOption: any) => void;
  loading: AsyncStatus;
  updateLoading: (loading: AsyncStatus) => void;
  rows: any;
  updateRows: (rows: any) => void;
  dateRange: IDateOption;
  updateDateRange: React.Dispatch<React.SetStateAction<IDateOption>>;
  refreshTable: boolean;
  updateRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
  editedCells: any;
  updateEditedCells: (editedCells: any) => void;
  revertCells: boolean;
  updateRevertCells: React.Dispatch<React.SetStateAction<boolean>>;
  // chart
  showChart: boolean;
  updateShowChart: React.Dispatch<React.SetStateAction<boolean>>;

  selectedVariantId: string;
  updateVariantId: React.Dispatch<React.SetStateAction<string>>;
  selectedProductId: string;
  updateProductId: React.Dispatch<React.SetStateAction<string>>;
  selectedRowData: any;
  updateRowData: React.Dispatch<React.SetStateAction<any>>;

  //time series
  timeSeriesTime: IDateOption;
  updatTimeSeriesTime: (KPITime: IDateOption) => void;
  inventoryTimeSeriesTime: IDateOption;
  updateInventoryTimeSeriesTime: (KPITime: IDateOption) => void;
  timeSeriesType: IOption;
  updateTimeSeriesType: React.Dispatch<React.SetStateAction<IOption>>;
  timeSeriesFrequency: IOption;
  updateTimeSeriesFrequency: React.Dispatch<React.SetStateAction<IOption>>;
}

export const PlanningContext = React.createContext<IPlanningContext>({
  statesFetched: false,
  updateStatesFetched: () => {},
  sorting: [],
  updateSorting: () => {},
  planningFilter: [{ key: "more", value: "Filter & Search", name: "Filter & Search" }],
  updatePlanningFilter: () => {},
  fPlanningCategories: [],
  updateFPlanningCategories: () => {},
  fPlanningProducts: [],
  updateFPlanningProducts: () => {},
  fPlanningSKUs: [],
  updateFPlanningSKUs: () => {},
  fPlanningVariants: [],
  updateFPlanningVariants: () => {},
  vendor: "",
  setVendor: () => {},
  vendorOptions: {},
  setVendorOptions: () => {},
  loading: AsyncStatus.Loading,
  updateLoading: () => {},
  rows: [],
  updateRows: () => {},
  dateRange: {
    startDate: new Date(),
    endDate: new Date(),
    key: "custom",
    name: "Custom",
  },
  updateDateRange: () => {},
  refreshTable: false,
  updateRefreshTable: () => {},
  editedCells: [],
  updateEditedCells: () => {},
  revertCells: true,
  updateRevertCells: () => {},
  // chart
  showChart: true,
  updateShowChart: () => {},
  selectedVariantId: "",
  updateVariantId: () => {},
  selectedProductId: "",
  updateProductId: () => {},
  selectedRowData: {},
  updateRowData: () => {},
  //time series
  timeSeriesTime: {
    startDate: new Date(),
    endDate: new Date(),
    key: "custom",
    name: "Custom",
  },
  updatTimeSeriesTime: () => {},
  inventoryTimeSeriesTime: DATE_OPTIONS_FORECAST[2],
  updateInventoryTimeSeriesTime: () => {},
  timeSeriesType: TIME_SERIES_OPTIONS[0],
  updateTimeSeriesType: () => {},
  timeSeriesFrequency: DATE_FREQUENCY_TIME_SERIES[0],
  updateTimeSeriesFrequency: () => {},
});

interface PlanningContextProps {
  children: React.ReactNode;
}

export const PlanningProvider = (props: PlanningContextProps) => {
  const { rawState } = useContext(StatesContext);
  const [statesFetched, setStatesFetched] = useState(false);
  const [planningFilter, setPlanningFilter] = useState<IFilterPlus[]>([
    { key: "recommendedPurchases", value: "Recommended Purchases", name: "Recommended Purchases" },
    { key: "more", value: "Filter & Search", name: "Filter & Search" },
  ]);

  const [sorting, setSorting] = useState<SortingState>([
    { id: "demandOverSellingHorizon", desc: true },
  ]);
  const [fPlanningCategories, setFPlanningCategories] = useState<string[]>([]);
  const [fPlanningProducts, setFPlanningProducts] = useState<string[]>([]);
  const [fPlanningSKUs, setFPlanningSKUs] = useState<string[]>([]);
  const [fPlanningVariants, setFPlanningVariants] = useState<string[]>([]);
  const [vendor, setVendor] = useState("");
  const [vendorOptions, setVendorOptions] = useState<any>([]);
  const [loading, setLoading] = useState(AsyncStatus.Loading);
  const [rows, setRows] = useState(Array(35).fill({ ...PLACEHOLDER_ROW_PLANNING }));
  const [refreshTable, setRefreshTable] = useState(false);
  const [editedCells, setEditedCells] = useState([]);
  const [revertCells, setRevertCells] = useState(false);
  // new
  const [showChart, setShowChart] = useState(false);
  const [selectedVariantId, setVariantId] = useState("");
  const [selectedProductId, setProductId] = useState("");
  const [selectedRowData, setRowData] = useState({})
  const endDate = new Date();
  endDate.setMonth(endDate.getMonth() + 4);
  endDate.setDate(0);

  //time series
  const [timeSeriesTime, setTimeSeriesTime] = useState({
    startDate: rewindDays(new Date(new Date().toISOString().slice(0, 10)), 90),
    endDate: new Date(endDate.toISOString().slice(0, 10)),
    key: "custom",
    name: "Custom",
  });
  const [timeSeriesFrequency, setTimeSeriesFrequency] = useState(DATE_FREQUENCY_TIME_SERIES[0]);
  const [inventoryTimeSeriesTime, setInventoryTimeSeriesTime] = useState(DATE_OPTIONS_FORECAST[2]);
  const [timeSeriesType, setTimeSeriesType] = useState(TIME_SERIES_OPTIONS[1]);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().toISOString().slice(0, 10)),
    endDate: new Date(endDate.toISOString().slice(0, 10)),
    key: "custom",
    name: "Custom",
  });

  useEffect(() => {
    if (rawState) {
      const parsed = JSON.parse(rawState);
      if (parsed) {
        processAndSetTime(parsed, "planning_time", setDateRange, PLANNING_DATE_OPTIONS);
        processAndSet(parsed, "planning_filter", setPlanningFilter);
        processAndSet(parsed, "planning_org", setVendor);
      }
      setStatesFetched(true);
    }
  }, [rawState]);

  useEffect(() => {
    if (statesFetched) {
      saveState("planning_filter", planningFilter);
    }
  }, [JSON.stringify(planningFilter), statesFetched]);

  return (
    <PlanningContext.Provider
      value={{
        statesFetched,
        updateStatesFetched: setStatesFetched,
        sorting,
        updateSorting: setSorting,
        planningFilter,
        updatePlanningFilter: setPlanningFilter,
        fPlanningCategories,
        updateFPlanningCategories: setFPlanningCategories,
        fPlanningProducts,
        updateFPlanningProducts: setFPlanningProducts,
        fPlanningSKUs,
        updateFPlanningSKUs: setFPlanningSKUs,
        fPlanningVariants,
        updateFPlanningVariants: setFPlanningVariants,
        vendor,
        setVendor,
        vendorOptions,
        setVendorOptions,
        rows,
        updateRows: setRows,
        loading,
        updateLoading: setLoading,
        dateRange,
        updateDateRange: setDateRange,
        refreshTable,
        updateRefreshTable: setRefreshTable,
        editedCells,
        updateEditedCells: setEditedCells,
        revertCells,
        updateRevertCells: setRevertCells,
        // chart
        showChart,
        updateShowChart: setShowChart,
        selectedVariantId,
        updateVariantId: setVariantId,
        selectedProductId,
        updateProductId: setProductId,
        selectedRowData,
        updateRowData: setRowData,
        // time series
        timeSeriesTime,
        updatTimeSeriesTime: setTimeSeriesTime,
        inventoryTimeSeriesTime,
        updateInventoryTimeSeriesTime: setInventoryTimeSeriesTime,
        timeSeriesType,
        updateTimeSeriesType: setTimeSeriesType,
        timeSeriesFrequency,
        updateTimeSeriesFrequency: setTimeSeriesFrequency,
      }}
    >
      {props.children}
    </PlanningContext.Provider>
  );
};
