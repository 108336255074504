import { useState, useRef, Fragment, useEffect, lazy, Suspense } from "react";
import { Transition } from "@headlessui/react";
import { ReactComponent as Angledown } from "assets/images/angledown.svg";
import { FrequencyOptionKeys, IDateOption } from "types";
import useOutsideClick from "hooks/useOutsideClick";
import { DateRangePicker } from "components/DateRangePicker/DateRangePicker";
import { PillCallout } from "components/Pill";

import { LineLegend } from "components/ChartLegend";
import { ApplyButton } from "components/Button";
import { calculateDayCount, calculateWeekCount, daysBetween } from "utils/date";

const Tooltip = lazy(() =>
  import("@material-tailwind/react").then((module) => ({ default: module.Tooltip }))
);

export const DateRangeDropdown = ({
  options,
  option,
  onSelect,
  disabled,
  allowFuture,
  allowPast,
  frequency,
  openDropdown,
  fixedDays,
}: {
  options: IDateOption[];
  option: IDateOption;
  onSelect: (selection: IDateOption) => void;
  disabled?: boolean;
  allowFuture?: boolean;
  allowPast?: boolean;
  frequency?: FrequencyOptionKeys;
  openDropdown?: boolean;
  fixedDays?: number;
}) => {
  const [dropdown, setDropdown] = useState(false);
  const childrenRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<IDateOption>(option);

  const [startDate, setStartDate] = useState(option.startDate);
  const [endDate, setEndDate] = useState(option.endDate);
  const [positionStyle, setPositionStyle] = useState({
    top: "initial",
    left: "initial",
    right: "initial",
  });
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (childrenRef.current) {
      const rect = childrenRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;

      if (rect.right + 300 > windowWidth) {
        setPositionStyle((prevStyle) => ({
          ...prevStyle,
          left: "auto",
          right: "0",
        }));
      } else {
        setPositionStyle((prevStyle) => ({
          ...prevStyle,
          left: `0`,
          right: "initial",
        }));
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (!dropdown && openDropdown) {
      setDropdown(true);
    }
  }, [openDropdown]);

  useEffect(() => {
    setStartDate(option.startDate);
    setEndDate(option.endDate);
    setSelected(option);
  }, [option]);
  useOutsideClick(childrenRef, () => {
    setDropdown(false);
  });

  const transitionClasses = {
    enter: "transform transition duration-[150ms]",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "transform transition duration-[150ms]",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0",
  };

  return (
    <div
      className="relative inline-block text-left font-mono text-sm text-[#6D6392]"
      ref={childrenRef}
    >
      <div>
        {
          selected.key === "custom" && (
            <PillCallout color={"dark"} position="top-right">{daysBetween(startDate, endDate)} Days</PillCallout>
          )
        }
        <button
          disabled={disabled}
          onClick={() => {
            !disabled && setDropdown(!dropdown);
          }}
          type="button"
          className={`inline-flex w-full justify-center items-center rounded-md px-2 py-1 border bg-base-lightwhite border-border-internal
            ${disabled ? "opacity-50" : "hover:bg-highlight-gentle"}
            transition-all
            focus:bg-highlight-gentle focus:outline-none focus:ring-2 focus:ring-[#4F369B] focus:ring-offset-2 focus:ring-offset-gray-100`}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          {selected.key === "custom" ? (
            <DateRangeDisplay startDate={startDate} endDate={endDate} />
          ) : (
            selected.name
          )}
          <Angledown className={dropdown ? `rotate-180 transition-all` : `transition-all`} />
        </button>
      </div>
      <Transition show={dropdown} as={Fragment} {...transitionClasses}>
        <div
          className={`absolute z-40 mt-2 w-72 origin-top-right rounded-md bg-highlight-gentle shadow-lg focus:outline-none border border-border-internal`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          style={positionStyle}
        >
          {options.map((item) => {
            let tooltipStyle =
              item.key === "custom"
                ? "invisible"
                : "z-[100] font-mono relative bg-highlight-gentle border border-border-internal max-w-sm text-[#6D6392] shadow-popup rounded-md leading-5 overflow-hidden";
            return (
              <Suspense fallback={<div></div>} key={item.key}>
                <Tooltip
                  content={<DateRangeDisplay startDate={item.startDate} endDate={item.endDate} />}
                  placement="right"
                  key={item.key}
                  className={tooltipStyle}
                >
                  <div
                    className={`text-base-textmedium text-xs flex items-center cursor-pointer hover:bg-gray-300/30 h-6 px-2 transition-all ${
                      selected.key === item.key
                        ? " bg-gray-300/30 text-purple-hover font-extrabold"
                        : ""
                    }`}
                    onClick={() => {
                      setSelected(item);
                    }}
                  >
                    {item.name}
                  </div>
                </Tooltip>
              </Suspense>
            );
          })}
          {selected.key === "custom" && (
            <div className="px-2">
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                allowFuture={allowFuture}
                allowPast={allowPast}
                fixedDays={fixedDays}
              />
            </div>
          )}
          <div className="px-2">{message}</div>
          <div className="text-white mb-2">
            <ApplyButton
              onClick={() => {
                const newStartDate = selected.key === "custom" ? startDate : selected.startDate;
                const newEndDate = selected.key === "custom" ? endDate : selected.endDate;

                const dayCount = calculateDayCount(newStartDate, newEndDate);
                const weekCount = calculateWeekCount(newStartDate, newEndDate);
                if (dayCount > 90 && frequency === "day") {
                  setMessage("For daily frequency please select lower date range than 90 days");
                } else if (weekCount > 90 && frequency === "week") {
                  setMessage("For weekly frequency please select lower date range than 90 weeks");
                } else {
                  setDropdown(false);
                  setMessage("");
                  onSelect({ ...selected, startDate: newStartDate, endDate: newEndDate });
                }
              }}
              disabled={false}
            />
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default DateRangeDropdown;

const DateRangeDisplay = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
  return (
    <div className="px-1 cursor-pointer flex gap-2 rounded-md items-center justify-center">
      <StringDate date={startDate} label="Start:" />
      <LineLegend lineColor="bg-gray-300" />
      <StringDate date={endDate} label="End:" />
    </div>
  );
};

export const StringDate = ({ date, label }: { date: Date; label: string }) => {
  return (
    <div className="flex align-center rounded-md font-medium ">
      <span>{date.toLocaleDateString()}</span>
    </div>
  );
};
