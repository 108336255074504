import React, { useState, useCallback, useContext, useEffect } from "react";
import ThreeStateCheckbox from "components/Checkbox";
import Loading, { LoadingWrapper } from "components/Loading";
import { ReactComponent as Search } from "assets/images/search.svg";
import { getHighlightedText } from "utils/helper";
import { AsyncStatus, type CheckboxState, type DropdownItem, type IProduct } from "types";
import { Index, IndexRange, InfiniteLoader, List } from "react-virtualized";
import "react-virtualized/styles.css";
import { ApplyButton } from "components/Button";

interface Props {
  data: DropdownItem[];
  selected: string[];
  onApply: (ids: string[]) => void;
  loading: AsyncStatus;
  disableApply: boolean;
  fetchData: () => void;
}

const SearchAndSelectDropdown = ({
  data,
  selected,
  onApply,
  loading,
  disableApply,
  fetchData,
}: Props) => {
  const [selections, setSelections] = useState<string[]>(selected || []);
  const [filteredItems, setFilteredItems] = useState<DropdownItem[]>(data);
  const [searchKeyword, setSearchkeyword] = useState("");

  useEffect(() => {
    console.log('selections ', selections);
  }, [selections]);
  useEffect(() => {
    if (data.length === 0) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (searchKeyword) {
      const keywordLower = searchKeyword.toLowerCase();
      const filtered = data.filter(
        (item) =>
          item.title.toLowerCase().includes(keywordLower) ||
          item.id.toLowerCase().includes(keywordLower)
      );
      setFilteredItems(filtered);
    } else {
      setFilteredItems(data);
    }
  }, [data, searchKeyword]);

  const handleChangeAll = (state: CheckboxState) => {
    let newSelection: string[] = [];
    if (state === true) {
      newSelection = filteredItems.map((item) => item.id);
    }
    setSelections(newSelection);
  };

  const handleChangeItem = (state: CheckboxState, name: string) => {
    let newSelection: string[] = [];
    if (state === true) {
      newSelection = [...selections, name];
    } else if (state === false) {
      newSelection = selections.filter((id) => id !== name);
    }
    setSelections(newSelection);
  };

  const getCheckboxState = useCallback(() => {

    const length = selections.filter((p) => data.map((item) => item.id).includes(p)).length;

    if (length === 0) {
      return false;
    }
    if (length === filteredItems.length) {
      return true;
    }
    return null;
  }, [selections, data]);

  const [loadedItems, setLoadedItems] = useState<number[]>([]);

  const isItemLoaded = ({ index }: Index): boolean => loadedItems.includes(index);

  const loadMoreItems = ({ startIndex, stopIndex }: IndexRange): Promise<any> => {
    return new Promise(() => {
      const newLoadedItems = [...loadedItems];
      for (let i = startIndex; i <= stopIndex; i++) {
        newLoadedItems.push(i);
      }
      setLoadedItems(newLoadedItems);
    });
  };

  const renderItem = ({
    index,
    key,
    style,
  }: {
    index: number;
    key: React.Key;
    style: React.CSSProperties;
  }) => (
    <li
      key={key}
      style={style}
      className="flex items-center h-8 pl-5 pr-2 hover:rounded-full hover:bg-border-hover/40 hover:font-bold"
    >
      <ThreeStateCheckbox
        id={filteredItems[index].id}
        name={filteredItems[index].id}
        checked={selections.includes(filteredItems[index].id)}
        onChange={handleChangeItem}
      />
      <label
        htmlFor={filteredItems[index].id}
        className="w-full pl-2.5 overflow-hidden text-ellipsis whitespace-nowrap"
      >
        {getHighlightedText(filteredItems[index].title, searchKeyword)}
      </label>
    </li>
  );

  return (
    <div>
      <div>
        <Search className="absolute w-10 h-10 m-[2.5px] text-base-text" />
        <input
          type="text"
          className="w-full h-[45px] bg-base-gentle rounded-xl outline-none pl-12 pr-4 text-base text-purple-base appearance-none"
          value={searchKeyword}
          onChange={(e) => setSearchkeyword?.(e.target.value)}
        />
      </div>
      <LoadingWrapper loading={loading} styles="h-[30px] w-full rounded-md mt-2 text-center">
        <>
          <div className="flex items-center h-8 px-2">
            <ThreeStateCheckbox
              id="selectAll"
              name="selectAll"
              checked={getCheckboxState()}
              onChange={handleChangeAll}
              disabled={filteredItems.length > 1000}
            />
            <label
              htmlFor="selectAll"
              className="w-full font-bold pl-2.5 max-w-xs overflow-hidden text-ellipsis whitespace-nowrap"
            >
              {filteredItems.length > 1000
                ? "Can't select all when > 1000 items"
                : `Select All (${filteredItems.length} items)`}
            </label>
          </div>
          <div className="font-medium">
            <div className="max-h-96">
              <InfiniteLoader
                isRowLoaded={isItemLoaded}
                loadMoreRows={loadMoreItems}
                rowCount={filteredItems.length}
              >
                {({ onRowsRendered, registerChild }) => (
                  <List
                    ref={registerChild}
                    rowCount={filteredItems.length}
                    rowHeight={40}
                    rowRenderer={renderItem}
                    onRowsRendered={onRowsRendered}
                    width={300}
                    height={288}
                  />
                )}
              </InfiniteLoader>
            </div>
          </div>

          <ApplyButton onClick={() => onApply(selections)} disabled={disableApply} />
        </>
      </LoadingWrapper>
    </div>
  );
};

export default SearchAndSelectDropdown;
