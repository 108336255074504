import {
  ResponsiveContainer,
  Line,
  LineChart,
} from "recharts";
import {
  formatNumber,
  formatPercent
} from "utils/helper-ts";

export const RegressionSpark = ({ cell, currency }: { cell: any; currency: string }) => {
  let slope = cell.row.original.slope;
  let data = cell.row.original.reChart;

  // console.log("Regression Spark", {
  //   slope,
  //   data,
  //   currency
  // })


  return (
    <div style={{ width: "100%", height: 30 }} className="gap-2 h-full grid grid-cols-6  ">
      <RatePoint revenue={data[0].revenue} currency={currency} />
      <div className="col-span-3 px-1">
        <ResponsiveContainer>
          <LineChart width={80} height={30} data={data}>
            <Line
              type="linear"
              dataKey="revenue"
              stroke={Math.sign(slope) === -1 ? "#f87171" : "#4ade80"}
              strokeWidth={1.5}
              dot={{ strokeWidth: 1.5, r: 1, strokeDasharray: "" }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <RatePoint revenue={data[1].revenue} currency={currency} />
      <Slope slope={slope} />
      {/* <ChangePill change={slope} decimal /> */}
    </div>
  );
};

const Slope = ({ slope }: { slope: number }) => {
  return (
    <p
      style={{ color: `${Math.sign(slope) === -1 ? "#b91c1c" : "#15803d"}` }}
      className="font-mono rounded-md bg-gray-100 text-2xs h-full w-full flex items-center justify-center"
    >
      {formatPercent(slope)}
    </p>
  );
};

const RatePoint = ({ revenue, currency }: { revenue: number; currency: string }) => {
  return (
    <p className="font-mono text-2xs h-full w-full flex items-center justify-center">
      {formatNumber(revenue, "currencyRate", currency, "d")}
    </p>
  );
};
